import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import logo from '/@/assets/shared/logo/gift-wallet-logo-with-title.svg';
import { BaseButton } from '../../shared/button/baseButton/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '../../shared/typography/Index';

const StyledContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(6),
}));
const StyledTitleContainer = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  color: theme.palette.secondary.dark,
  textAlign: 'center',
}));
const StyledDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.common.black,
  textAlign: 'center',
}));
const StyledTopPageLinkContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: '100%',
}));

const Presenter: React.FC<Record<string, never>> = () => (
  <MainLayout>
    <StyledContainer>
      <img src={logo} alt="gift wallet logo" width="100%" height="26" />
      <StyledTitleContainer>
        <BaseTypography
          fontType={FONT_TYPE.HEADLINE}
          fontSize={FONT_SIZE.MD}
          isBold
        >
          お探しのページが
          <br />
          見つかりませんでした
        </BaseTypography>
      </StyledTitleContainer>
      <StyledDescriptionContainer>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
          URLをご確認いただくか、サービストップより
          <br />
          ページをお探しください。
        </BaseTypography>
      </StyledDescriptionContainer>
      <StyledTopPageLinkContainer>
        <BaseButton fullWidth component={Link} to={PATHS.HOME}>
          トップページへ戻る
        </BaseButton>
      </StyledTopPageLinkContainer>
    </StyledContainer>
  </MainLayout>
);

export { Presenter };
