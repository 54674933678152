import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';

const StyledDescription = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.secondary.dark,
}));

const Presenter: React.FC<Record<string, never>> = () => (
  <div data-testid="signInPageAboutSection">
    <StyledDescription>
      <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD} isBold>
        gift walletは、
        <br />
        せっかくのギフトを使い忘れなく、
        <br />
        しっかり活用するためのお財布サービスです。
      </BaseTypography>
    </StyledDescription>
  </div>
);

export { Presenter };
