import React from 'react';
import { Presenter } from './Presenter';
export const FONT_TYPE = {
  HEADLINE: 'headline',
  BODY: 'body',
} as const;
export const FONT_SIZE = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
} as const;
export type fontType = (typeof FONT_TYPE)[keyof typeof FONT_TYPE];
export type fontSize = (typeof FONT_SIZE)[keyof typeof FONT_SIZE];
type Props = {
  fontType: fontType;
  fontSize: fontSize;
  isBold?: boolean;
  children: React.ReactNode;
  component?: React.ElementType;
};
const Container: React.FC<Props> = ({
  fontType,
  fontSize,
  isBold = false,
  children,
  component,
}) => {
  return (
    <Presenter
      fontType={fontType}
      fontSize={fontSize}
      isBold={isBold ?? fontType === FONT_TYPE.HEADLINE}
      component={component}
    >
      {children}
    </Presenter>
  );
};

export { Container as BaseTypography };
