import React from 'react';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Container)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.contrast,
  padding: theme.spacing(3, 2) + ' !important',
}));

type Props = {
  children: React.ReactNode;
};

const Presenter: React.FC<Props> = ({ children }) => (
  <StyledCard>{children}</StyledCard>
);

export { Presenter };
