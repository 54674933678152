import React from 'react';
import {
  Typography,
  Container,
  Grid,
  Button,
  ButtonProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AvailableMyGifts } from './availableMyGifts/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { SelectedMyGiftsState } from '/@/store/app/selectedMyGifts';
import { DiscardConfirmationModal } from './discardConfirmationModal/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1, 2),
}));
const StyledTitleContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
type StyledButtonProps = ButtonProps & { to: string };
const StyledMyGiftButton = styled(Button)<StyledButtonProps>(({ theme }) => ({
  minWidth: '0',
  color: theme.palette.primary.dark,
  '&.MuiButton-contained': {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.hint,
      color: theme.palette.secondary.main,
    },
  },
}));
const StyledMyGiftButtonLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '0.75rem',
  lineHeight: '1.5',
  textDecoration: 'underline',
}));
const StyledDiscardButtonContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  width: `calc(100vw - ${theme.spacing(6)})`,
  maxWidth: `calc(600px - ${theme.spacing(6)})`,
}));
type Props = {
  selectedMyGifts: SelectedMyGiftsState;
  handleMyGiftsDiscardConfirmationModalOpen: () => void;
  isDiscardButtonDisabled: boolean;
};

const Presenter: React.FC<Props> = ({
  selectedMyGifts,
  handleMyGiftsDiscardConfirmationModalOpen,
  isDiscardButtonDisabled,
}) => (
  <MainLayout>
    <StyledContainer>
      <Grid container alignItems="center" justifyContent="space-between">
        <StyledTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.MD}
            isBold
          >
            マイギフト選択
          </BaseTypography>
        </StyledTitleContainer>
        <StyledMyGiftButton
          variant="text"
          disableElevation
          component={Link}
          to={PATHS.HOME}
        >
          <StyledMyGiftButtonLabel color="inherit" variant="caption">
            マイギフトに戻る
          </StyledMyGiftButtonLabel>
        </StyledMyGiftButton>
      </Grid>
      <AvailableMyGifts />
      <StyledDiscardButtonContainer>
        <BaseButton
          onClick={handleMyGiftsDiscardConfirmationModalOpen}
          disabled={isDiscardButtonDisabled}
          data-gtm-click="giftsPage-editPage-discardButton"
        >
          {selectedMyGifts.length}件を利用済みにする
        </BaseButton>
      </StyledDiscardButtonContainer>
    </StyledContainer>
    <DiscardConfirmationModal
      handleMyGiftsDiscardConfirmationModalOpen={
        handleMyGiftsDiscardConfirmationModalOpen
      }
    />
  </MainLayout>
);

export { Presenter };
