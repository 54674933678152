import React, { useEffect, useRef } from 'react';
import { useAppSelector } from '/@/store';
import { Presenter } from './Presenter';
import { useAppDispatch } from '/@/store/hooks';
import { STATUS } from '/@/store/api/constants';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { pointLogsWithDetail as pointLogsWithDetailSelector } from '/@/store/page/pointLogsPage/listPage';
import { getPointLogsThunk } from '/@/store/api/queries/getPointLogs';

const Container: React.FC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();

  const pointLogsWithDetail = useAppSelector(pointLogsWithDetailSelector);
  const getPointLogsListPageInfo = useAppSelector(
    (state) => state.page.pointLogsPage.listPage.pageInfo,
  );
  const getPointLogsStatus = useAppSelector(
    (state) => state.api.getPointLogs.status,
  );

  useEffect(() => {
    // pageInfoがnullの場合に初回ロードを行う
    if (getPointLogsListPageInfo === null) {
      dispatch(getPointLogsThunk()).catch(() => {});
    }
  }, [dispatch, getPointLogsListPageInfo]);

  // 無限スクロール対応
  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      getPointLogsStatus !== STATUS.LOADING &&
      getPointLogsListPageInfo?.hasNextPage
    ) {
      dispatch(
        getPointLogsThunk({
          after: getPointLogsListPageInfo.endCursor ?? undefined,
        }),
      ).catch(() => {});
    }
  }, [dispatch, isIntersect, getPointLogsStatus, getPointLogsListPageInfo]);

  return (
    <Presenter
      infiniteScrollTarget={infiniteScrollTarget}
      pointLogsWithDetail={pointLogsWithDetail}
      getPointLogsStatus={getPointLogsStatus}
    />
  );
};

export { Container as PointLogList };
