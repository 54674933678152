import React from 'react';
import { styled } from '@mui/material/styles';
import { PointFeatureGroups } from '/@/store/app/pointFeatureGroups';
import { PointFeatureGroup } from './pointFeatureGroup/Index';
import { PointFeatureGroup as PointFeatureGroupType } from '/@/api/graphql/internalApi/types';

const StyledPointFeatureGroupsContainer = styled('div')({});

type Props = {
  pointFeatureGroups: PointFeatureGroups;
};

const Presenter: React.FC<Props> = ({ pointFeatureGroups }) => {
  if (!pointFeatureGroups || pointFeatureGroups.length === 0) {
    return null;
  }
  return (
    <StyledPointFeatureGroupsContainer data-testid="component-shared-pointFeatureGroups">
      {pointFeatureGroups?.map((pointFeatureGroup) => (
        <PointFeatureGroup
          key={pointFeatureGroup?.uid}
          pointFeatureGroup={pointFeatureGroup as PointFeatureGroupType}
        />
      ))}
    </StyledPointFeatureGroupsContainer>
  );
};

export { Presenter };
