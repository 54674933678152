import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const StyledCautionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[900],
  fontSize: '0.75rem',
  textAlign: 'left',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  paddingLeft: '0.75rem', // ※の位置調整
  textIndent: '-0.75rem', // ※の位置調整
}));
const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

const Presenter: React.FC<Record<string, never>> = () => (
  <>
    <StyledCautionText>
      ※Amazon、Amazon.co.jpおよびそれらのロゴは Amazon.com, Inc.
      またはその関連会社の商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※本プログラムは株式会社ギフティによる提供です。本プログラムについてのお問い合わせは
      Amazonではお受けしておりません。 gift walletにログイン後の「
      <StyledLink to={PATHS.INQUIRIES_NEW}>お問い合わせ</StyledLink>
      」よりお願いいたします。
    </StyledCautionText>
    <StyledCautionText>
      ※GOおよび、GOチケットのロゴはGO株式会社の商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※本プログラムは株式会社ギフティによる提供です。本プログラムについてのお問い合わせはGOではお受けしておりません。
      gift walletにログイン後の「
      <StyledLink to={PATHS.INQUIRIES_NEW}>お問い合わせ</StyledLink>
      」よりお願いいたします。
    </StyledCautionText>
    <StyledCautionText>
      ※Coke ONはThe Coca-Cola Companyの登録商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※ © 2024 iTunes K.K. All rights reserved.
    </StyledCautionText>
    <StyledCautionText>
      ※Google Play は Google LLCの商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※「au PAY」は、KDDI株式会社の商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※PayPayポイントは出金、譲渡不可です。PayPay／PayPayカード公式ストアでも利用可能です。
    </StyledCautionText>
    <StyledCautionText>
      ※「nanaco(ナナコ)」と「nanacoギフト」は株式会社セブン・カードサービスの登録商標です。
      「nanacoギフト」は、株式会社セブン・カードサービスとの発行許諾契約により、株式会社NTTカードソリューションが発行する電子マネーギフトサービスです。
      本プログラムについてのお問い合わせは株式会社セブン・カードサービスではお受けしておりません。
      gift Walletにログイン後の「
      <StyledLink to={PATHS.INQUIRIES_NEW}>お問い合わせ</StyledLink>
      」よりお願いいたします。
    </StyledCautionText>
    <StyledCautionText>
      ※Kyash（Visaプリペイドカード）ギフトコードは、決済と送金機能に利用できるKyashバリュー残高としてご利用いただけます。Visa
      & QUICPay＋加盟店で利用可能です。
    </StyledCautionText>
    <StyledCautionText>
      ※「Kyash」は株式会社Kyashの登録商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※「EdyギフトID」は、楽天Edy株式会社との発行許諾契約により、株式会社NTTカードソリューションが発行する電子マネーギフトサービスです。
      「楽天Edy（ラクテンエディ）」は、楽天グループのプリペイド型電子マネーサービスです。
      「EdyギフトID」を登録することで、楽天Edyとして受け取ることができます。
    </StyledCautionText>
    <StyledCautionText>
      ※「QUOカードPay」もしくは「クオ・カード
      ペイ」およびそれらのロゴは㈱クオカードの登録商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※「WAONポイントID」は、イオンフィナンシャルサービス株式会社との発行許諾契約により、株式会社NTTカードソリューションが発行する電子マネーギフトサービスです。
    </StyledCautionText>
    <StyledCautionText>
      ※「WAON（ワオン）」は、イオン株式会社の登録商標です。
    </StyledCautionText>
  </>
);

export { Presenter };
