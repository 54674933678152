import React from 'react';
import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { Modal } from '/@/components/shared/modal/Index';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';
import { BaseButton } from '../../button/baseButton/Index';

const StyledContents = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));
const StyledMessageContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 'bold',
  fontSize: '1rem',
  lineHeight: '1.4rem',
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
}));
const StyledPointSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  '& > :not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));
const StyledPointRow = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const StyledPointTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 'bold',
  color: theme.palette.grey[700],
}));
const StyledConsumptionPoints = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  lineHeight: '1',
  fontWeight: 'bold',
  fontFamily: 'Grandstander-Bold',
  color: theme.palette.secondary.dark,
}));
const StyledRemainingPoints = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  lineHeight: '1',
  fontWeight: 'bold',
  fontFamily: 'Grandstander-Bold',
  color: theme.palette.secondary.dark,
}));
const StyledExchangeSubmitButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.875),
  fontWeight: 'bold',
  lineHeight: '1',
  borderRadius: theme.spacing(2.75),
  boxShadow: 'none',
  backgroundColor: theme.palette.common.black,
}));
const StyledCancelButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

type Props = {
  cancelExchange: () => void;
  handleSubmit: () => void;
  myPoints: number | undefined;
  pointAmount: number | undefined;
  createPointExchangeStatus: string;
};

const Presenter: React.FC<Props> = ({
  cancelExchange,
  handleSubmit,
  myPoints,
  pointAmount,
  createPointExchangeStatus,
}) => (
  <Modal handleClose={cancelExchange}>
    <StyledContents container direction="column">
      <StyledMessageContainer>
        <BaseTypography
          fontType={FONT_TYPE.BODY}
          fontSize={FONT_SIZE.MD}
          isBold
        >
          ポイントを使用してギフトを交換します。
          <br />
          よろしいですか？
        </BaseTypography>
      </StyledMessageContainer>
      <StyledPointSection>
        <StyledPointRow container alignItems="center">
          <StyledPointTitle>使用ポイント</StyledPointTitle>
          <StyledConsumptionPoints>
            {pointAmount?.toLocaleString()}
          </StyledConsumptionPoints>
        </StyledPointRow>
        <StyledPointRow container alignItems="center">
          <StyledPointTitle>使用後残りポイント</StyledPointTitle>
          <StyledRemainingPoints>
            {pointAmount !== undefined &&
            myPoints !== undefined &&
            myPoints - pointAmount >= 0
              ? (myPoints - pointAmount)?.toLocaleString()
              : null}
          </StyledRemainingPoints>
        </StyledPointRow>
      </StyledPointSection>
      <StyledExchangeSubmitButton
        variant="contained"
        color="primary"
        loading={createPointExchangeStatus === 'loading'}
        onClick={handleSubmit}
        disabled={
          pointAmount === undefined ||
          myPoints === undefined ||
          pointAmount > myPoints ||
          createPointExchangeStatus === 'loading'
        }
        data-gtm-click="shared-modal-itemExchangeModal-submitButton"
      >
        交換する
      </StyledExchangeSubmitButton>
      <StyledCancelButtonContainer>
        <BaseButton
          isTransparent
          onClick={cancelExchange}
          data-gtm-click="shared-modal-itemExchangeModal-cancelButton"
        >
          キャンセル
        </BaseButton>
      </StyledCancelButtonContainer>
    </StyledContents>
  </Modal>
);

export { Presenter };
