import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '/@/store';
import { Presenter } from './Presenter';
import {
  selectTypicalSelectedMyGifts,
  selectOtherSelectedMyGifts,
} from '/@/store/app/selectedMyGifts';
import { discardMyGiftsThunk } from '/@/store/api/mutations/discardMyGifts';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { discardMyGiftsSelectors } from '/@/store/api/mutations/discardMyGifts';

type OwnProps = {
  handleMyGiftsDiscardConfirmationModalOpen: () => void;
};

const Container: React.FC<OwnProps> = ({
  handleMyGiftsDiscardConfirmationModalOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const myGiftsDiscardConfirmationModalOpen = useAppSelector(
    (state) => state.ui.myGiftsDiscardConfirmationModalOpen,
  );
  const typicalSelectedMyGifts = useAppSelector(selectTypicalSelectedMyGifts);
  const otherSelectedMyGifts = useAppSelector(selectOtherSelectedMyGifts);
  const selectedMyGifts = useAppSelector((state) => state.app.selectedMyGifts);
  const isDiscardMyGiftsSuccess = useAppSelector(
    discardMyGiftsSelectors.selectIsDiscardMyGiftsSuccess,
  );
  const isDiscardMyGiftsLoading = useAppSelector(
    discardMyGiftsSelectors.selectIsDiscardMyGiftsLoading,
  );

  const handleDiscardMyGifts = () => {
    dispatch(
      discardMyGiftsThunk({
        myGiftUrlCodes: selectedMyGifts.map((myGift) => myGift.urlCode),
      }),
    ).catch(() => {});
  };

  useEffect(() => {
    if (isDiscardMyGiftsSuccess) {
      navigate(PATHS.HOME);
    }
  }, [isDiscardMyGiftsSuccess, navigate]);

  return (
    <Presenter
      myGiftsDiscardConfirmationModalOpen={myGiftsDiscardConfirmationModalOpen}
      handleMyGiftsDiscardConfirmationModalOpen={
        handleMyGiftsDiscardConfirmationModalOpen
      }
      typicalSelectedMyGifts={typicalSelectedMyGifts}
      otherSelectedMyGifts={otherSelectedMyGifts}
      handleDiscardMyGifts={handleDiscardMyGifts}
      isDiscardMyGiftsLoading={isDiscardMyGiftsLoading}
    />
  );
};

export { Container as DiscardConfirmationModal };
