import * as React from 'react';
import { styled } from '@mui/material/styles';
import { fontSize, fontType, FONT_SIZE } from './Index';
import { Typography, TypographyProps } from '@mui/material';

type StyledTypographyProps = TypographyProps & {
  fontType: fontType;
  fontSize: fontSize;
  isBold: boolean;
  component?: React.ElementType;
};

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'fontType' && prop !== 'fontSize' && prop !== 'isBold',
})<StyledTypographyProps>(({ fontType, fontSize, isBold }) => ({
  fontSize:
    fontType === 'headline'
      ? fontSize === FONT_SIZE.SM
        ? '0.875rem'
        : fontSize === FONT_SIZE.MD
          ? '1rem'
          : fontSize === FONT_SIZE.LG
            ? '1.3125rem'
            : '1.5rem'
      : fontSize === FONT_SIZE.SM
        ? '0.75rem'
        : fontSize === FONT_SIZE.MD
          ? '0.875rem'
          : '1rem',
  lineHeight: '1.75',
  fontWeight: isBold ? 'bold' : 'normal',
  display: 'block',
}));

type Props = {
  fontType: fontType;
  fontSize: fontSize;
  isBold: boolean;
  children: React.ReactNode;
  component?: React.ElementType;
};

const Presenter: React.FC<Props> = ({
  fontType,
  fontSize,
  isBold,
  children,
  component = 'span',
}) => (
  <StyledTypography
    fontType={fontType}
    fontSize={fontSize}
    isBold={isBold}
    component={component}
  >
    {children}
  </StyledTypography>
);

export { Presenter };
