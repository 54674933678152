import React, { useEffect, useMemo, useState } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { itemDetailActions } from '/@/store/app/itemDetail';
import {
  initialiseCreatePointExchangeInput,
  setCreatePointExchangeInput,
} from '/@/store/app/createPointExchangeInput/actions';
import createRequestUid from '/@/utils/createRequestUid';
import { getPointWalletThunk } from '/@/store/api/queries/getPointWallet';
import { exchangeItemType } from '/@/store/app/shared/types';
import { initialiseAlertMessage } from '/@/store/ui/alertMessage/actions';
import { useDataLayer } from '/@/common/hooks/useDataLayer';

const Container: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pushPageViewEvent } = useDataLayer();
  const itemDetail = useAppSelector((state) => state.app.itemDetail);
  const myPoints = useAppSelector((state) => state.app.pointWallet?.point);
  const [isOpenItemExchangeModal, setIsOpenItemExchangeModal] =
    useState<boolean>(false);
  const [variablePoint, setVariablePoint] = useState<number>(1);
  const [variablePointRange, setVariablePointRange] = useState<{
    min: number;
    max: number;
  }>({ min: 1, max: 1 });
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null,
  );
  const exchangeItemType = useMemo<exchangeItemType>(() => {
    return itemDetail?.currentItemPointPhase?.variable
      ? 'variable'
      : 'fixedAmount';
  }, [itemDetail]);

  const isDisabledChargeButton = useMemo(() => {
    return (
      exchangeItemType === 'variable' &&
      (variablePoint < variablePointRange.min ||
        variablePoint > variablePointRange.max ||
        variablePoint %
          (itemDetail?.currentItemPointPhase?.variable?.step ?? 1) !==
          0)
    );
  }, [exchangeItemType, variablePoint, variablePointRange, itemDetail]);

  const handleChangeVariablePoint = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPoint = Number(e.target.value.replace(/,/g, ''));

    if (
      isNaN(newPoint) ||
      itemDetail?.currentItemPointPhase?.variable?.maxPoint === undefined ||
      myPoints === undefined
    ) {
      return;
    } else if (myPoints && newPoint > myPoints) {
      setValidationMessage('所持ポイントが足りません');
    } else if (
      newPoint > itemDetail.currentItemPointPhase.variable.maxPoint ||
      newPoint < variablePointRange.min
    ) {
      setValidationMessage(`
        ポイントは、${variablePointRange.min.toLocaleString()}から
        ${itemDetail.currentItemPointPhase.variable.maxPoint.toLocaleString()}の間で入力してください。
        `);
    } else {
      setValidationMessage(null);
    }
    setVariablePoint(newPoint);
  };

  const handleCloseDetailModal = () => {
    dispatch(itemDetailActions.initialiseItemDetail());
    dispatch(initialiseAlertMessage());
  };

  const handleSwitchItemExchangeModal = () => {
    if (isOpenItemExchangeModal) {
      // モーダルを閉じる際に、入力値を初期化する
      initialiseCreatePointExchangeInput();
      setIsOpenItemExchangeModal(false);
    } else if (itemDetail?.uid && itemDetail?.currentItemPointPhase?.point) {
      // モーダルを開く際に、入力値を設定する
      const requestUid = createRequestUid();
      dispatch(
        setCreatePointExchangeInput({
          exchangeItemType: exchangeItemType,
          pointExchange: {
            itemUid: itemDetail.uid,
            pointAmount:
              exchangeItemType === 'fixedAmount'
                ? itemDetail.currentItemPointPhase?.point
                : variablePoint,
            pointBackCampaignUid: itemDetail.currentPointBackCampaign?.uid,
            requestUid: requestUid,
          },
        }),
      );
      setIsOpenItemExchangeModal(true);
    }
  };

  useEffect(() => {
    // バリアブル商品の場合、所持ポイントor交換上限を初期値とする
    if (myPoints && itemDetail?.currentItemPointPhase?.variable) {
      setVariablePoint(
        Math.min(
          myPoints,
          itemDetail?.currentItemPointPhase?.variable.maxPoint,
        ),
      );
      setVariablePointRange({
        min: itemDetail?.currentItemPointPhase?.variable.minPoint || 1,
        max: Math.min(
          myPoints,
          itemDetail?.currentItemPointPhase?.variable.maxPoint,
        ),
      });
    }
    if (myPoints === undefined) {
      dispatch(getPointWalletThunk());
    }
  }, [myPoints, itemDetail, dispatch]);

  useEffect(() => {
    if (itemDetail) {
      // 商品詳細画面を表示中はbodyタグにoverflow: hiddenを設定
      document.body.style.overflow = 'hidden';

      pushPageViewEvent({
        page: 'itemDetail',
        customData: {
          page: 'itemDetail',
          itemUid: itemDetail.uid,
          itemName: itemDetail.contentName,
          brandUid: itemDetail.brand.uid,
          brandName: itemDetail.brand.name,
        },
      });
    } else {
      document.body.style.overflow = '';
    }
  }, [itemDetail, pushPageViewEvent]);

  return (
    <Presenter
      onClose={handleCloseDetailModal}
      itemDetail={itemDetail}
      isOpenItemExchangeModal={isOpenItemExchangeModal}
      handleSwitchItemExchangeModal={handleSwitchItemExchangeModal}
      exchangeItemType={exchangeItemType}
      variablePoint={variablePoint}
      handleChangeVariablePoint={handleChangeVariablePoint}
      isDisabledChargeButton={isDisabledChargeButton}
      validationMessage={validationMessage}
    />
  );
};

export { Container as ItemDetailModal };
