const types = {
  GET_EXIST_SAME_CONFIRMED_EMAIL_START:
    'api/GET_EXIST_SAME_CONFIRMED_EMAIL_START',
  GET_EXIST_SAME_CONFIRMED_EMAIL_SUCCESS:
    'api/GET_EXIST_SAME_CONFIRMED_EMAIL_SUCCESS',
  GET_EXIST_SAME_CONFIRMED_EMAIL_FAILURE:
    'api/GET_EXIST_SAME_CONFIRMED_EMAIL_FAILURE',
} as const;

export { types };
