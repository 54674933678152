import React, { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { signInOrSignUpThunk } from '/@/store/api/mutations/signInOrSignUp';
import { getOauthSessionThunk } from '/@/store/api/queries/getOauthSession';
import { STATUS } from '/@/store/api/constants';
import { setSessionTokenToCookie } from '/@/utils/setSessionTokenToCookie';
import {
  decideGoForwardLocation,
  decideGoBackLocation,
} from '/@/utils/decideLocationAfterOauth';
import { selectLineAuthenticationFailedMessage } from '/@/store/api/mutations/signInOrSignUp/selectors';

const Container: React.FC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();

  const currentSession = useAppSelector((state) => state.app.currentSession);
  const signInOrSignUpApiStatus = useAppSelector(
    (state) => state.api.signInOrSignUp.status,
  );

  const oauthSession = useAppSelector(
    (state) => state.page.oauthPage.authorizationPage.oauthSession,
  );
  const getOauthSessionApiStatus = useAppSelector(
    (state) => state.api.getOauthSession.status,
  );

  const lineAuthenticationFailedMessage = useAppSelector(
    selectLineAuthenticationFailedMessage,
  );

  const { search } = useLocation();
  const navigate = useNavigate();

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const authorizationCode = urlSearchParams.get('code');
  const state = urlSearchParams.get('state');

  // 画面表示時にクエリパラメータ中の認可コードを使ってサインインし、ユーザーセッションを Cookie に格納する

  useEffect(() => {
    if (
      authorizationCode !== null &&
      state !== null &&
      signInOrSignUpApiStatus === STATUS.IDLE
    ) {
      dispatch(signInOrSignUpThunk({ authorizationCode, state })).catch(
        () => {},
      );
    }
  }, [dispatch, authorizationCode, state, signInOrSignUpApiStatus]);

  useEffect(() => {
    const lineAuthorizationError = urlSearchParams.get('error');

    if (lineAuthorizationError && oauthSession) {
      navigate(decideGoBackLocation(oauthSession));
    }
  }, [dispatch, navigate, oauthSession, urlSearchParams]);

  useEffect(() => {
    if (signInOrSignUpApiStatus === STATUS.SUCCESS && currentSession !== null) {
      setSessionTokenToCookie(currentSession.token);
    }
  }, [signInOrSignUpApiStatus, currentSession]);

  // 画面表示時にクエリパラメータ中の state を使って OauthSession を取得し、次の画面に遷移する

  useEffect(() => {
    const state = urlSearchParams.get('state');
    if (state !== null && getOauthSessionApiStatus === STATUS.IDLE) {
      dispatch(getOauthSessionThunk({ state })).catch(() => {});
    }
  }, [dispatch, urlSearchParams, getOauthSessionApiStatus]);

  useEffect(() => {
    if (
      getOauthSessionApiStatus === STATUS.SUCCESS &&
      oauthSession !== null &&
      currentSession !== null
    ) {
      navigate(decideGoForwardLocation(oauthSession));
    }
  }, [getOauthSessionApiStatus, oauthSession, currentSession, navigate]);

  useEffect(() => {
    if (lineAuthenticationFailedMessage && oauthSession) {
      navigate(decideGoBackLocation(oauthSession));
    }
  }, [navigate, lineAuthenticationFailedMessage, oauthSession]);

  return <Presenter />;
};

export { Container as OauthCallbackPage };
