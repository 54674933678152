import { types } from './types';
import type { ApiError } from '/@/api/graphql/client';
import { VerifyEmailMutation } from '/@/api/graphql/publicApi/types';

export const VerifyEmailStart = () =>
  ({ type: types.VERIFY_EMAIL_START }) as const;

export const VerifyEmailSuccess = (
  loginSession: NonNullable<VerifyEmailMutation['verifyEmail']['loginSession']>,
) =>
  ({
    type: types.VERIFY_EMAIL_SUCCESS,
    loginSession,
  }) as const;

export const VerifyEmailFailure = (error: ApiError) =>
  ({
    type: types.VERIFY_EMAIL_FAILURE,
    error,
  }) as const;
