import { combineReducers } from 'redux';
import { myGiftsEditPageActions } from './giftsPage/editPage';
import {
  inquiriesNewPage,
  inquiriesNewPageActions,
} from './inquiriesPage/newPage';
import { oauthPage } from './oauthPage/';
import { pointLogsPage } from './pointLogsPage';
import { signInPage } from './signInPage';
import { signUpPage, signUpPageActions } from './signUpPage';
import {
  userProfileNewPage,
  userProfileNewPageActions,
} from './userPage/profilePage/newPage/';

export const pageReducer = combineReducers({
  inquiriesNewPage,
  signInPage,
  signUpPage,
  oauthPage,
  pointLogsPage,
  userProfileNewPage,
});

export const pageActions = {
  ...inquiriesNewPageActions,
  ...signUpPageActions,
  ...userProfileNewPageActions,
  ...myGiftsEditPageActions,
};
