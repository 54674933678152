import { types } from './types';
import { ApiError } from '/@/api/graphql/client';
import type { GetExistSameConfirmedEmailGqlQuery } from '/@/api/graphql/publicApi/types';

export const getExistSameConfirmedEmailStart = () =>
  ({
    type: types.GET_EXIST_SAME_CONFIRMED_EMAIL_START,
  }) as const;

export const getExistSameConfirmedEmailSuccess = (
  existSameConfirmedEmail: GetExistSameConfirmedEmailGqlQuery['existSameConfirmedEmail'],
) =>
  ({
    type: types.GET_EXIST_SAME_CONFIRMED_EMAIL_SUCCESS,
    existSameConfirmedEmail,
  }) as const;

export const getExistSameConfirmedEmailFailure = (error: ApiError) =>
  ({
    type: types.GET_EXIST_SAME_CONFIRMED_EMAIL_FAILURE,
    error,
  }) as const;
