import React from 'react';
import {
  Typography,
  TypographyProps,
  Grid,
  Container,
  ContainerProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MYGIFT_DISPLAY_SIZE, MyGiftDisplaySize } from '../Index';
import ArrowRightIcon from '/@/assets/shared/icon/arrow-right-icon.svg';

type StyledContainerProps = {
  myGiftDisplaySize: MyGiftDisplaySize;
} & ContainerProps;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'myGiftDisplaySize',
})<StyledContainerProps>(({ theme, myGiftDisplaySize }) => ({
  backgroundColor: theme.palette.common.white,
  padding:
    myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD
      ? 0 + ' !important'
      : theme.spacing(2) + ' !important',
}));
type StyledContainerContentProps = {
  myGiftDisplaySize: MyGiftDisplaySize;
  isDisplayIcon: boolean;
} & React.ImgHTMLAttributes<HTMLImageElement>;
const StyledContainerContent = styled(Grid, {
  shouldForwardProp: (prop) =>
    prop !== 'myGiftDisplaySize' && prop !== 'isDisplayIcon',
})<StyledContainerContentProps>(
  ({ theme, myGiftDisplaySize, isDisplayIcon }) => ({
    minWidth: 0,
    width: `calc(100% - ${myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? '64px' : '40px'} - ${theme.spacing(1.5)} ${isDisplayIcon ? ' - 20px' : ''})`,
  }),
);
type StyledMyGiftNameProps = {
  myGiftDisplaySize: MyGiftDisplaySize;
} & TypographyProps;
const StyledMyGiftName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'myGiftDisplaySize',
})<StyledMyGiftNameProps>(({ theme, myGiftDisplaySize }) => ({
  color: theme.palette.secondary.dark,
  fontWeight: 'bold',
  overflow: 'hidden',
  fontSize:
    myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? '0.875rem' : '0.75rem',
  lineHeight: '1.5',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}));
type StyledPointChargeableTextProps = {
  myGiftDisplaySize: MyGiftDisplaySize;
} & TypographyProps;
const StyledPointChargeableText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'myGiftDisplaySize',
})<StyledPointChargeableTextProps>(({ theme, myGiftDisplaySize }) => ({
  fontSize:
    myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? '0.875rem' : '0.75rem',
  lineHeight: '1.5',
  fontWeight: 'bold',
  color: '#1DABD6',
  marginLeft: theme.spacing(1),
  display: 'inline-block',
}));
type StyledMyGiftImageProps = {
  myGiftDisplaySize: MyGiftDisplaySize;
} & React.ImgHTMLAttributes<HTMLImageElement>;
const StyledMyGiftImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'myGiftDisplaySize',
})<StyledMyGiftImageProps>(({ theme, myGiftDisplaySize }) => ({
  marginRight: theme.spacing(1.5),
  height: myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? '64px' : '40px',
  width: myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? '64px' : '40px',
  borderRadius: theme.spacing(),
  border: `1px solid ${theme.palette.secondary.light}`,
  objectFit: 'contain',
}));

const StyledMyGiftExpiredAt = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '1.5',
  marginTop: theme.spacing(0.5),
  maxWidth: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: theme.palette.grey[700],
  fontWeight: 'bold',
}));

type Props = {
  name: string;
  imageUrl: string;
  expirationText: string;
  pointMergeable: boolean;
  handleClick: () => void;
  myGiftDisplaySize: MyGiftDisplaySize;
  isHiddenMergeableText: boolean;
  isHiddenArrowIcon: boolean;
};

const Presenter: React.FC<Props> = ({
  name,
  imageUrl,
  expirationText,
  pointMergeable,
  handleClick,
  myGiftDisplaySize,
  isHiddenMergeableText,
  isHiddenArrowIcon,
}) => (
  <StyledContainer
    onClick={handleClick}
    data-gtm-click="shared-myGift-myGiftSkelton-card"
    myGiftDisplaySize={myGiftDisplaySize}
  >
    <Grid
      container
      wrap="nowrap"
      direction="row"
      justifyContent="space-between"
    >
      <StyledMyGiftImage
        src={imageUrl}
        alt={name}
        myGiftDisplaySize={myGiftDisplaySize}
      />
      <StyledContainerContent
        container
        justifyContent="center"
        direction="column"
        myGiftDisplaySize={myGiftDisplaySize}
        isDisplayIcon={
          myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD && !isHiddenArrowIcon
        }
      >
        <StyledMyGiftName
          variant="subtitle1"
          myGiftDisplaySize={myGiftDisplaySize}
        >
          {name}
          {pointMergeable && !isHiddenMergeableText && (
            <StyledPointChargeableText myGiftDisplaySize={myGiftDisplaySize}>
              ポイントに移行可能
            </StyledPointChargeableText>
          )}
        </StyledMyGiftName>
        <Grid container alignItems="center">
          <StyledMyGiftExpiredAt variant="caption">
            {expirationText}
          </StyledMyGiftExpiredAt>
        </Grid>
      </StyledContainerContent>
      {myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD && !isHiddenArrowIcon && (
        <Grid container alignItems="center" justifyContent="center">
          <img src={ArrowRightIcon} alt="arrow-right" width="20" height="20" />
        </Grid>
      )}
    </Grid>
  </StyledContainer>
);

export { Presenter };
