import React from 'react';
import { Box, Typography } from '@mui/material';
import { Grid, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HeroSection } from './heroSection/Index';
import { AboutSection } from '/@/components/shared/lp/aboutSection/Index';
import { BenefitsOfConvertSection } from '/@/components/shared/lp/benefitsOfConvertSection/Index';
import { HowToUseSection } from '/@/components/shared/lp/howToUseSection/Index';
import { CampaignSection } from '/@/components/shared/lp/campaignSection/Index';
import { CautionText } from '/@/components/shared/cautionText/Index';
import { Footer } from '/@/components/templates/footer/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import { LP_TYPES } from '/@/components/shared/lp/types';
import { PATHS } from '/@/routes/paths';
import { formatedISO8601Date } from '/@/utils/formatDate';
import { neoPublicAssetsListForLpChargeSerialCodePage } from '/@/utils/constPublicAssetsList';
import { Sticky } from '/@/components/shared/sticky/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';

const StyledContainer = styled(Container)({
  padding: 0,
});
const StyledSection = styled('section')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 2),
  paddingTop: theme.spacing(3),
  '& + &': {
    paddingTop: theme.spacing(4),
  },
}));
const StyledLoginSectionContainer = styled('section')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));
const StyledSerialCodeOutOfPeriodMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
}));
const StyledSerialCodePeriodMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));
const StyledStickyContainer = styled('div')({
  display: 'flex',
});
const StyledStickyNoteContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(3),
  color: theme.palette.secondary.dark,
}));
const StyledStickyLoginButtonContainer = styled('div')({
  minWidth: '200px',
});

type Props = LoginOption & {
  isSerialCodeOutOfPeriod: boolean;
  serialCodeEndAt: string | null;
  publicAssetsImage: (key: string) => string;
};

const Presenter: React.FC<Props> = ({
  url,
  origin,
  referer,
  convertToPoint,
  destination,
  serialCode,
  isSerialCodeOutOfPeriod,
  serialCodeEndAt,
  publicAssetsImage,
}) => (
  <Grid container direction="column" alignContent="center">
    <StyledContainer maxWidth="sm">
      <StyledSection>
        <HeroSection>
          {isSerialCodeOutOfPeriod && (
            <StyledSerialCodeOutOfPeriodMessage
              variant="body2"
              data-cy="serialCodePage-expiredAlertMessage"
            >
              {`このURLは有効期限切れです`}
            </StyledSerialCodeOutOfPeriodMessage>
          )}
          {serialCodeEndAt && !isSerialCodeOutOfPeriod && (
            <StyledSerialCodePeriodMessage
              variant="body2"
              data-cy="serialCodePage-expiredAlertMessage"
            >
              {`チャージの有効期限は${formatedISO8601Date(serialCodeEndAt.replace(' ', '+'))}です`}
            </StyledSerialCodePeriodMessage>
          )}
          <StyledLoginSectionContainer>
            <LoginButton
              url={url}
              origin={origin}
              referer={referer}
              convertToPoint={convertToPoint}
              destination={destination}
              serialCode={serialCode}
              flow={PATHS.LP_CHARGE_SERIAL_CODE}
            >
              ポイントをチャージする
            </LoginButton>
          </StyledLoginSectionContainer>
        </HeroSection>
      </StyledSection>
      <StyledSection>
        <CampaignSection
          highReturnBrandsImageURL={publicAssetsImage(
            neoPublicAssetsListForLpChargeSerialCodePage.HIGH_RETURN_BRANDS_IMAGE,
          )}
          exchangeableBrandsImageURL={publicAssetsImage(
            neoPublicAssetsListForLpChargeSerialCodePage.EXCHANGEABLE_BRANDS_IMAGE,
          )}
        />
      </StyledSection>
      <StyledSection>
        <AboutSection lpType={LP_TYPES.LP_CHARGE_SERIAL_CODE}>
          <StyledLoginSectionContainer>
            <LoginButton
              url={url}
              origin={origin}
              referer={referer}
              convertToPoint={convertToPoint}
              destination={destination}
              serialCode={serialCode}
              flow={PATHS.LP_CHARGE_SERIAL_CODE}
            >
              ポイントをチャージする
            </LoginButton>
          </StyledLoginSectionContainer>
        </AboutSection>
      </StyledSection>
      <StyledSection>
        <BenefitsOfConvertSection
          lpType={LP_TYPES.LP_CHARGE_SERIAL_CODE}
          subsection1ImageURL={publicAssetsImage(
            neoPublicAssetsListForLpChargeSerialCodePage.BENEFITS_OF_CONVERT_SECTION_SUBSECTION1,
          )}
          subsection3ImageURL={publicAssetsImage(
            neoPublicAssetsListForLpChargeSerialCodePage.BENEFITS_OF_CONVERT_SECTION_SUBSECTION3,
          )}
        />
      </StyledSection>
      <StyledSection>
        <HowToUseSection />
      </StyledSection>
      <StyledSection>
        <LoginButton
          url={url}
          origin={origin}
          referer={referer}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
          flow={PATHS.LP_CHARGE_SERIAL_CODE}
        >
          ポイントをチャージする
        </LoginButton>
      </StyledSection>
      <StyledSection>
        <CautionText />
      </StyledSection>
      <Box mt={4} />
      <Footer />
      <Sticky>
        <StyledStickyContainer>
          <StyledStickyNoteContainer>
            <BaseTypography
              fontType={FONT_TYPE.BODY}
              fontSize={FONT_SIZE.SM}
              component="span"
            >
              シリアルコードをお持ちの方は
            </BaseTypography>
          </StyledStickyNoteContainer>
          <StyledStickyLoginButtonContainer>
            <LoginButton
              url={url}
              origin={origin}
              referer={referer}
              convertToPoint={convertToPoint}
              destination={destination}
              serialCode={serialCode}
              flow={PATHS.LP_CHARGE_SERIAL_CODE}
              isDisplayButtonOnly
            >
              ポイントチャージ
            </LoginButton>
          </StyledStickyLoginButtonContainer>
        </StyledStickyContainer>
      </Sticky>
    </StyledContainer>
  </Grid>
);

export { Presenter };
