import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import QuestionIcon from '/@/assets/shared/icon/question-icon.svg';
import { BenefitOfPointChargeModal } from '../modal/Index';
import { theme } from '/@/utils/theme';

const StyledBenefitOfPointChargeModalButton = styled(Button)(({ theme }) => ({
  textAlign: 'center',
  textDecoration: 'none',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(2.75),
  border: `2px solid ${theme.palette.secondary.light}`,
}));
const StyledQuestionIcon = styled('img')(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  marginRight: theme.spacing(0.5),
}));
const StyledPointQuestionText = styled(Typography)({
  color: theme.palette.common.black,
  fontWeight: 'bold',
  fontSize: '0.75rem',
  lineHeight: '0.75rem',
});

type Props = {
  isOpenModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  fullWidth?: boolean;
};

const Presenter: React.FC<Props> = ({
  isOpenModal,
  handleOpenModal,
  handleCloseModal,
  fullWidth,
}) => (
  <>
    <StyledBenefitOfPointChargeModalButton
      onClick={handleOpenModal}
      disableElevation
      variant="contained"
      data-gtm-click="shared-benefitOfPointCharge-modalWithButton-openButton"
      fullWidth={fullWidth}
    >
      <StyledQuestionIcon src={QuestionIcon} alt="question" />
      <StyledPointQuestionText>ポイントとは</StyledPointQuestionText>
    </StyledBenefitOfPointChargeModalButton>
    {isOpenModal && (
      <BenefitOfPointChargeModal handleClose={handleCloseModal} />
    )}
  </>
);

export { Presenter };
