import React from 'react';
import { Presenter } from './Presenter';
import { useAppSelector, useAppDispatch } from '/@/store';
import { myGiftsDiscardConfirmationModalOpenActions } from '/@/store/ui/myGiftsDiscardConfirmationModalOpen';
import { selectIsDiscardButtonDisabled } from '/@/store/app/selectedMyGifts';

const Container: React.FC<Record<string, never>> = () => {
  const selectedMyGifts = useAppSelector((state) => state.app.selectedMyGifts);

  const dispatch = useAppDispatch();

  const handleMyGiftsDiscardConfirmationModalOpen = () =>
    dispatch(
      myGiftsDiscardConfirmationModalOpenActions.toggleMyGiftsDiscardConfirmationModal(),
    );

  const isDiscardButtonDisabled = useAppSelector(selectIsDiscardButtonDisabled);

  return (
    <Presenter
      selectedMyGifts={selectedMyGifts}
      handleMyGiftsDiscardConfirmationModalOpen={
        handleMyGiftsDiscardConfirmationModalOpen
      }
      isDiscardButtonDisabled={isDiscardButtonDisabled}
    />
  );
};

export { Container as GiftsEditPage };
