import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getFrequentlyAskedQuestionsThunk } from '/@/store/api/queries/getFrequentlyAskedQuestions';

const Container: React.FC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const frequentlyAskedQuestions = useAppSelector(
    (state) => state.app.frequentlyAskedQuestions,
  );

  useEffect(() => {
    dispatch(getFrequentlyAskedQuestionsThunk());
  }, [dispatch]);

  return <Presenter frequentlyAskedQuestions={frequentlyAskedQuestions} />;
};

export { Container as FrequentlyAskedQuestionsPage };
