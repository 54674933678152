import React from 'react';
import { Presenter } from './Presenter';
import { GifteeContentBase } from '/@/store/app/shared/types';

export type GifteeContent = GifteeContentBase;
type Props = {
  gifteeContent: GifteeContent;
};

const Container: React.FC<Props> = ({ gifteeContent }) => {
  return <Presenter gifteeContent={gifteeContent} />;
};

export { Container as GifteeContent };
