import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Presenter } from './Presenter';
import { PATHS } from '/@/routes/paths';
import { useAppDispatch, useAppSelector } from '/@/store';
import { STATUS } from '/@/store/api/constants';
import { verifyEmailThunk } from '/@/store/api/mutations/verifyEmail';
import { selectVerifyEmailErrorMessage } from '/@/store/api/mutations/verifyEmail/selectors';
import { setSessionTokenToCookie } from '/@/utils/setSessionTokenToCookie';

const Container: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const dispatch = useAppDispatch();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const confirmationToken = urlSearchParams.get('confirmation_token') as string;
  const registrationSessionUid = urlSearchParams.get('uid') as string;
  const verifyEmailApiStatus = useAppSelector(
    (state) => state.api.verifyEmail.status,
  );
  const verifyEmailErrorMessage = useAppSelector(selectVerifyEmailErrorMessage);
  const currentSession = useAppSelector((state) => state.app.currentSession);

  useEffect(() => {
    dispatch(
      verifyEmailThunk({
        confirmationToken,
        registrationSessionUid,
      }),
    ).catch(() => {});
  }, [dispatch, navigate, confirmationToken, registrationSessionUid]);

  useEffect(() => {
    if (verifyEmailApiStatus === STATUS.SUCCESS && currentSession !== null) {
      setSessionTokenToCookie(currentSession.token);
      navigate(PATHS.HOME);
    }
  }, [verifyEmailApiStatus, currentSession, navigate]);

  return <Presenter verifyEmailErrorMessage={verifyEmailErrorMessage} />;
};

export { Container as SignUpVerifyEmailPage };
