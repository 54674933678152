import { Grid, styled } from '@mui/material';
import React from 'react';
import giftWalletLogo from '/@/assets/shared/logo/gift-wallet-logo-with-title.svg';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';

type Props = LoginOption;

const StyledSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  borderRadius: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(2),
}));

const StyledGiftWalletLogo = styled('img')(({ theme }) => ({
  height: theme.spacing(3),
  marginBottom: theme.spacing(1),
}));

const StyledLineLoginButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledLoginButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const Presenter: React.FC<Props> = ({
  convertToPoint,
  destination,
  serialCode,
}) => {
  return (
    <>
      <StyledSection maxWidth="sm">
        <StyledGiftWalletLogo
          height={26}
          src={giftWalletLogo}
          alt="unclickableGiftWalletLogo"
        />
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
          これまでにgift walletを
        </BaseTypography>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
          利用されたことがある方はこちら
        </BaseTypography>
        <StyledLineLoginButtonContainer>
          <LoginButton
            url={null}
            origin={null}
            referer={null}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={serialCode}
            isDisplayButtonOnly
            isDisplayLineImage
          >
            LINEでログイン
          </LoginButton>
        </StyledLineLoginButtonContainer>
      </StyledSection>
      <StyledSection>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
          メールアドレスやその他ソーシャルアカウントで
        </BaseTypography>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
          ログインされる方はこちら
        </BaseTypography>
        <StyledLoginButtonContainer>
          <BaseButton>ログイン</BaseButton>
        </StyledLoginButtonContainer>
      </StyledSection>
    </>
  );
};

export { Presenter };
