import React, { useMemo, useState } from 'react';
import { Presenter } from './Presenter';
import expiredAtLabel from '/@/utils/expiredAtLabel';
import { useAppSelector } from '/@/store';

const Container: React.FC<Record<string, never>> = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const myGift = useAppSelector((state) => state.app.myGift);

  const label = useMemo(() => {
    return myGift !== null && myGift.giftType
      ? expiredAtLabel(myGift.giftType)
      : '';
  }, [myGift]);

  return myGift ? (
    <Presenter
      isOpenModal={isOpenModal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      myGift={myGift}
      expiredAtLabel={label}
    />
  ) : null;
};

export { Container as ChargeableGift };
