const types = {
  CREATE_USER_REGISTRATION_SESSION_START:
    'api/CREATE_USER_REGISTRATION_SESSION_START',
  CREATE_USER_REGISTRATION_SESSION_SUCCESS:
    'api/CREATE_USER_REGISTRATION_SESSION_SUCCESS',
  CREATE_USER_REGISTRATION_SESSION_FAILURE:
    'api/CREATE_USER_REGISTRATION_SESSION_FAILURE',
} as const;

export { types };
