import { handleErrorThunk } from '../../handleError';
import {
  createUserRegistrationSessionFailure,
  createUserRegistrationSessionStart,
  createUserRegistrationSessionSuccess,
} from './actions';
import type { ApiError } from '/@/api/graphql/client';
import type { CreateUserRegistrationSessionResponse } from '/@/api/graphql/publicApi/mutations/createUserRegistrationSession';
import { createUserRegistrationSession } from '/@/api/graphql/publicApi/mutations/createUserRegistrationSession';
import type { CreateUserRegistrationSessionInput } from '/@/api/graphql/publicApi/types';
import type { AppThunk } from '/@/store';

const createUserRegistrationSessionThunk =
  (input: CreateUserRegistrationSessionInput): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createUserRegistrationSessionStart());
    return createUserRegistrationSession(input)
      .then((response) =>
        dispatch(createUserRegistrationSessionSuccessThunk(response)),
      )
      .catch((error) =>
        dispatch(createUserRegistrationSessionFailureThunk(error)),
      );
  };

const createUserRegistrationSessionSuccessThunk =
  (response: CreateUserRegistrationSessionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(
      createUserRegistrationSessionSuccess(
        response.data!.createUserRegistrationSession.success,
      ),
    );
    return Promise.resolve();
  };

const createUserRegistrationSessionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createUserRegistrationSessionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { createUserRegistrationSessionThunk };
