import { types } from './types';

export const updateSignUpEmail = (email: string) =>
  ({
    type: types.UPDATE_EMAIL,
    payload: email,
  }) as const;

export const updateSignUpPassword = (password: string) =>
  ({
    type: types.UPDATE_PASSWORD,
    payload: password,
  }) as const;

export const updateSignUpIsTermsAgreed = (isTermsAgreed: boolean) =>
  ({
    type: types.UPDATE_IS_TERMS_AGREED,
    payload: isTermsAgreed,
  }) as const;

export const updateSignUpGenderCode = (genderCode: number) =>
  ({
    type: types.UPDATE_GENDER_CODE,
    payload: genderCode,
  }) as const;

export const updateSignUpBirthDayYear = (birthdayYear: string) =>
  ({
    type: types.UPDATE_BIRTHDAY_YEAR,
    payload: birthdayYear,
  }) as const;

export const updateSignUpBirthDayMonth = (birthdayMonth: string) =>
  ({
    type: types.UPDATE_BIRTHDAY_MONTH,
    payload: birthdayMonth,
  }) as const;

export const updateSignUpBirthDayDay = (birthdayDay: string) =>
  ({
    type: types.UPDATE_BIRTHDAY_DAY,
    payload: birthdayDay,
  }) as const;

export const updateSignUpPrefectureCode = (prefectureCode: number) =>
  ({
    type: types.UPDATE_PREFECTURE_CODE,
    payload: prefectureCode,
  }) as const;
