import { getGiftConvertToPointRequest } from '/@/api/graphql/internalApi/queries/getGiftConvertToPointRequest';
import type { GetGiftConvertToPointRequestResponse } from '/@/api/graphql/internalApi/queries/getGiftConvertToPointRequest';
import type { AppThunk } from '/@/store';
import {
  getGiftConvertToPointRequestStart,
  getGiftConvertToPointRequestSuccess,
  getGiftConvertToPointRequestFailure,
} from './actions';
import type { GetGiftConvertToPointRequestQueryVariables } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getGiftConvertToPointRequestThunk =
  (
    variables: GetGiftConvertToPointRequestQueryVariables,
  ): AppThunk<Promise<GetGiftConvertToPointRequestResponse | undefined>> =>
  (dispatch) => {
    dispatch(getGiftConvertToPointRequestStart());

    return getGiftConvertToPointRequest(variables)
      .then((response) => {
        dispatch(getGiftConvertToPointRequestSuccessThunk(response));
        return response;
      })
      .catch((error) => {
        dispatch(getGiftConvertToPointRequestFailureThunk(error));
        return undefined;
      });
  };

const getGiftConvertToPointRequestSuccessThunk =
  (response: GetGiftConvertToPointRequestResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { giftConvertToPointRequest } = response.data!;
    dispatch(getGiftConvertToPointRequestSuccess(giftConvertToPointRequest));
    return Promise.resolve();
  };

const getGiftConvertToPointRequestFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getGiftConvertToPointRequestFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getGiftConvertToPointRequestThunk };
