import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { getHtmlString } from '/@/utils/getHtmlString';
import { formatToDateAndTimeJa } from '/@/utils/formatDate';
import { AnnouncementState } from '/@/store/app/announcementDetail';
import { STATUS, Status } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';

const StyledAnnouncementDetailContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  textAlign: 'center',
}));

const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));

const StyledDateContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  textAlign: 'left',
  color: theme.palette.grey[700],
}));

const StyledBody = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: '0.875rem',
  color: theme.palette.secondary.dark,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
}));

const StyledImage = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  height: 'auto',
}));

type Props = {
  headerTitle: string;
  isDisplayAnnouncementDetail: boolean;
  announcementDetail: AnnouncementState;
  getAnnouncementStatus: Status;
  handleClickImage?: () => void;
};

const Presenter: React.FC<Props> = ({
  headerTitle,
  isDisplayAnnouncementDetail,
  announcementDetail,
  getAnnouncementStatus,
  handleClickImage,
}) => (
  <MainLayout headerTitle={headerTitle}>
    <StyledAnnouncementDetailContainer data-testid="page-announcementDetail">
      {getAnnouncementStatus === STATUS.LOADING && <PartialLoader />}
      {isDisplayAnnouncementDetail && announcementDetail !== null && (
        <>
          <StyledTitleContainer>
            <BaseTypography
              fontType={FONT_TYPE.HEADLINE}
              fontSize={FONT_SIZE.MD}
              isBold
            >
              {announcementDetail.title}
            </BaseTypography>
          </StyledTitleContainer>
          <StyledDateContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
              {formatToDateAndTimeJa(announcementDetail.createdAt)}
            </BaseTypography>
          </StyledDateContainer>
          {announcementDetail.content !== null && (
            <>
              {announcementDetail.content.linkUrl ? (
                <StyledImage
                  src={announcementDetail.content.imageFileUrl}
                  alt={'お知らせ画像'}
                  onClick={handleClickImage}
                />
              ) : (
                <StyledImage
                  src={announcementDetail.content.imageFileUrl}
                  alt={'お知らせ画像'}
                />
              )}
            </>
          )}
          {announcementDetail.body !== null && (
            <StyledBody
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: getHtmlString(announcementDetail.body),
              }}
            />
          )}
        </>
      )}
    </StyledAnnouncementDetailContainer>
  </MainLayout>
);

export { Presenter };
