import React from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PointWalletState } from '/@/store/app/pointWallet';
import { PointCard } from './pointCard/Index';
import { MYGIFT_DISPLAY_SIZE, MyGift } from '/@/components/shared/myGift/Index';
import type { MyGiftBase } from '/@/store/app/shared/types';
import { Card } from '/@/components/shared/card/Index';
import { STATUS, Status } from '/@/store/api/constants';
import { PointFeatureGroups } from '/@/components/shared/pointFeatureGroups/Index';

export type PointWallet = NonNullable<PointWalletState>;
const StyledPointBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));
const StyledPointBoxContainer = styled(Container)({
  padding: 0 + ' !important',
});

const StyledCardContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: 0 + ' !important',
}));
const StyledPointMergeableMyGiftsScrollContainer = styled(Grid)(
  ({ theme }) => ({
    margin: theme.spacing(0, -2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2),
    width: 'auto',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
);
const StyledMyGiftContainer = styled('div')({
  width: '100%',
});
const StyledMultiMyGiftsContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  maxWidth: '90%',
  '&:last-child': {
    marginRight: 0,
  },
}));
const StyledMyGift = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: theme.spacing(1),
}));
const StyledCaption = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.grey[700],
}));
const StyledPointFeatureGroupsContainer = styled(Container)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(2),
}));

type Props = {
  display: boolean;
  pointMergeableMyGifts: MyGiftBase[];
  pointConvertableMyGiftsConnectionStatus: Status;
};

const Presenter: React.FC<Props> = ({
  display,
  pointMergeableMyGifts,
  pointConvertableMyGiftsConnectionStatus,
}) => (
  <>
    {display && (
      <StyledPointBox data-testid="homePointSection">
        <StyledPointBoxContainer maxWidth="sm">
          <PointCard />
        </StyledPointBoxContainer>
        {pointMergeableMyGifts.length > 0 && (
          <StyledCardContainer
            maxWidth="sm"
            data-testid="homePointMergeableMyGifts"
          >
            <Card>
              <StyledCaption variant="body2">
                お持ちのギフトをポイントに移行しませんか？
              </StyledCaption>
              <StyledPointMergeableMyGiftsScrollContainer container>
                {pointMergeableMyGifts.length === 1 ? (
                  <StyledMyGiftContainer>
                    <StyledMyGift>
                      <MyGift
                        myGift={pointMergeableMyGifts[0]}
                        myGiftDisplaySize={MYGIFT_DISPLAY_SIZE.SM}
                        isHiddenMergeableText
                      />
                    </StyledMyGift>
                  </StyledMyGiftContainer>
                ) : (
                  pointMergeableMyGifts.map((myGift) => (
                    <StyledMultiMyGiftsContainer key={myGift.urlCode}>
                      <StyledMyGift>
                        <MyGift
                          myGift={myGift}
                          myGiftDisplaySize={MYGIFT_DISPLAY_SIZE.SM}
                          isHiddenMergeableText
                        />
                      </StyledMyGift>
                    </StyledMultiMyGiftsContainer>
                  ))
                )}
              </StyledPointMergeableMyGiftsScrollContainer>
            </Card>
          </StyledCardContainer>
        )}
        {pointMergeableMyGifts.length === 0 &&
          pointConvertableMyGiftsConnectionStatus !== STATUS.LOADING && (
            <StyledPointFeatureGroupsContainer maxWidth="sm">
              <PointFeatureGroups />
            </StyledPointFeatureGroupsContainer>
          )}
      </StyledPointBox>
    )}
  </>
);

export { Presenter };
