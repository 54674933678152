import React, { useState } from 'react';
import { Presenter } from './Presenter';
import { useAppSelector } from '/@/store';
import { selectIsGiftsEditPageButtonDisabled } from '/@/store/app/availableMyGiftsConnection/selectors';

const Container: React.FC<Record<string, never>> = () => {
  const [isDisplayUneditableModal, setIsDisplayUneditableModal] =
    useState<boolean>(false);
  const isGiftsEditPageButtonDisabled = useAppSelector(
    selectIsGiftsEditPageButtonDisabled,
  );
  return (
    <Presenter
      isGiftsEditPageButtonDisabled={isGiftsEditPageButtonDisabled}
      isDisplayUneditableModal={isDisplayUneditableModal}
      setIsDisplayUneditableModal={setIsDisplayUneditableModal}
    />
  );
};

export { Container as HomePage };
