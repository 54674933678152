import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import { FOOTER_HEIGHT } from '/@/components/templates/footer/Presenter';

const StyledMainContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  minHeight: `calc(100vh - ${FOOTER_HEIGHT}px)`,
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'left',
}));
const StyledNavigationRootButton = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'center',
}));

const Presenter: React.FC<Record<string, never>> = () => (
  <MainLayout>
    <StyledMainContainer maxWidth="sm">
      <StyledTitleContainer>
        <BaseTypography
          fontType={FONT_TYPE.HEADLINE}
          fontSize={FONT_SIZE.LG}
          isBold
        >
          送信しました
        </BaseTypography>
      </StyledTitleContainer>
      <StyledTextContainer data-testid="completion-text-container">
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
          お問い合わせいただき、ありがとうございます。
          <br />
          土日または祝日に送られた場合、返信は翌営業日以降になりますのでご了承ください。
          <br />
          平日の場合は、順に返信しますので、しばらくお待ちください。
        </BaseTypography>
      </StyledTextContainer>
      <StyledNavigationRootButton>
        <BaseButton
          component={Link}
          to={PATHS.ROOT}
          data-testid="return-to-top-button"
        >
          サービスTOPに戻る
        </BaseButton>
      </StyledNavigationRootButton>
    </StyledMainContainer>
  </MainLayout>
);

export { Presenter };
