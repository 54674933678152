const types = {
  UPDATE_EMAIL: 'page/signUpPage/UPDATE_EMAIL',
  UPDATE_PASSWORD: 'page/signUpPage/UPDATE_PASSWORD',
  UPDATE_IS_TERMS_AGREED: 'page/signUpPage/UPDATE_IS_TERMS_AGREED',
  UPDATE_PREFECTURE_CODE: 'page/signUpPage/UPDATE_PREFECTURE_CODE',
  UPDATE_GENDER_CODE: 'page/signUpPage/UPDATE_GENDER_CODE',
  UPDATE_BIRTHDAY_YEAR: 'page/signUpPage/UPDATE_BIRTHDAY_YEAR',
  UPDATE_BIRTHDAY_MONTH: 'page/signUpPage/UPDATE_BIRTHDAY_MONTH',
  UPDATE_BIRTHDAY_DAY: 'page/signUpPage/UPDATE_BIRTHDAY_DAY',
} as const;

export { types };
