import React from 'react';
import { BaseLabel } from '../baseLabel';
import { LabelSizeTypes } from '../baseLabel/Presenter';

type Props = {
  discountPercentage: number;
  labelSize: LabelSizeTypes;
  color: string;
  backgroundColor: string;
};

const Presenter: React.FC<Props> = ({
  discountPercentage,
  labelSize,
  color,
  backgroundColor,
}) => (
  <BaseLabel
    labelText={`${discountPercentage}%還元`}
    labelSize={labelSize}
    color={color}
    backgroundColor={backgroundColor}
  />
);

export { Presenter };
