import { SelectChangeEvent } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import { SignUpUserInput } from '../types';
import { Presenter } from './Presenter';

type Props = {
  isLoading: boolean;
  pushDatalayer: () => void;
  signUpUserInput: SignUpUserInput;
  setSignUpUserInput: React.Dispatch<React.SetStateAction<SignUpUserInput>>;
  handleClickBackButton: MouseEventHandler<HTMLButtonElement>;
};

const Container: React.FC<Props> = ({
  isLoading,
  pushDatalayer,
  signUpUserInput,
  setSignUpUserInput,
  handleClickBackButton,
}) => {
  const handleChangeGender = (selectedGenderCode: string) => {
    if (selectedGenderCode !== signUpUserInput.genderCode) {
      setSignUpUserInput((prevState) => ({
        ...prevState,
        genderCode: selectedGenderCode,
      }));
    }
  };

  const handleChangeBirthdayYear = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSignUpUserInput((prevState) => ({
      ...prevState,
      birthdayYear: event.target.value,
    }));
  };

  const handleChangeBirthdayMonth = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSignUpUserInput((prevState) => ({
      ...prevState,
      birthdayMonth: event.target.value,
    }));
  };

  const handleChangeBirthdayDay = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSignUpUserInput((prevState) => ({
      ...prevState,
      birthdayDay: event.target.value,
    }));
  };

  const handleChangePrefecture = (event: SelectChangeEvent<string | null>) => {
    setSignUpUserInput((prevState) => ({
      ...prevState,
      prefectureCode: event.target.value,
    }));
  };

  return (
    <Presenter
      isLoading={isLoading}
      pushDatalayer={pushDatalayer}
      signUpUserInput={signUpUserInput}
      handleChangeGender={handleChangeGender}
      handleChangeBirthdayYear={handleChangeBirthdayYear}
      handleChangeBirthdayMonth={handleChangeBirthdayMonth}
      handleChangeBirthdayDay={handleChangeBirthdayDay}
      handleChangePrefecture={handleChangePrefecture}
      handleClickBackButton={handleClickBackButton}
    />
  );
};

export { Container as ProfileInfo };
