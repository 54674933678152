import React from 'react';
import { Presenter } from './Presenter';
import { MyGiftBase } from '/@/store/app/shared/types';

type Props = {
  myGifts: MyGiftBase[];
};

const Container: React.FC<Props> = ({ myGifts }) => {
  return <Presenter myGifts={myGifts} />;
};

export { Container as GifteeContent };
