import React, { useMemo } from 'react';
import { Presenter } from './Presenter';
import { DestinationEnum } from '/@/api/graphql/publicApi/types';
import { LoginOption } from '/@/components/shared/loginButton/Index';

type Props = LoginOption;

const Container: React.FC<Props> = ({ serialCode }) => {
  const { search } = location;
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  // FIXME: 引き継ぎ予定でCIを通すためコメントアウト
  // const url = urlSearchParams.get('gift_url');
  // const origin = urlSearchParams.get('gift_origin') as OriginEnum | null;
  const convertToPoint = urlSearchParams.get('convert_to_point') === 'true';
  const destination = urlSearchParams.get(
    'destination',
  ) as DestinationEnum | null;

  return (
    <Presenter
      url={null}
      origin={null}
      referer={null}
      convertToPoint={convertToPoint}
      destination={destination}
      serialCode={serialCode}
    />
  );
};

export { Container as LoginSection };
