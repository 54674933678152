import {
  getExistSameConfirmedEmailFailure,
  getExistSameConfirmedEmailStart,
  getExistSameConfirmedEmailSuccess,
} from './actions';
import { ApiError } from '/@/api/graphql/client';
import type { GetExistSameConfirmedEmailResponse } from '/@/api/graphql/publicApi/queries/getExistSameConfirmedEmail';
import { getExistSameConfirmedEmail } from '/@/api/graphql/publicApi/queries/getExistSameConfirmedEmail';
import { GetExistSameConfirmedEmailGqlQueryVariables } from '/@/api/graphql/publicApi/types';
import type { AppThunk } from '/@/store';

const getExistSameConfirmedEmailThunk =
  (
    variable: GetExistSameConfirmedEmailGqlQueryVariables,
  ): AppThunk<Promise<boolean>> =>
  (dispatch) => {
    dispatch(getExistSameConfirmedEmailStart());
    return getExistSameConfirmedEmail(variable)
      .then((response) => {
        dispatch(getExistSameConfirmedEmailSuccessThunk(response));
        return Boolean(response?.data?.existSameConfirmedEmail);
      })
      .catch((error) => {
        dispatch(getExistSameConfirmedEmailFailureThunk(error));
        return false;
      });
  };

const getExistSameConfirmedEmailSuccessThunk =
  (response: GetExistSameConfirmedEmailResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(
      getExistSameConfirmedEmailSuccess(response.data!.existSameConfirmedEmail),
    );
    return Promise.resolve();
  };

const getExistSameConfirmedEmailFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getExistSameConfirmedEmailFailure(error));
    // NOTE: 500 ページに飛ばさないので、errorHandler を利用しない
    return Promise.reject(error);
  };

export { getExistSameConfirmedEmailThunk };
