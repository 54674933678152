import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '/@/store';
import { Presenter } from './Presenter';
import { getPublicAssetsThunk } from '/@/store/api/queries/getPublicAssets';
import { publicAssetsListForLpChargeSerialCodePage } from '/@/utils/constPublicAssetsList';

const Container: React.FC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const urlSearchParams = new URLSearchParams(search);
  const serialCode = urlSearchParams.get('serial_code');
  const serialCodeEndAt = urlSearchParams.get('end_at');

  const [isSerialCodeOutOfPeriod, setIsSerialCodeOutOfPeriod] =
    useState<boolean>(false);

  const publicAssets = useAppSelector((state) => state.app.publicAssets);
  const publicAssetsImage = (key: string) => {
    return (
      publicAssets?.find((asset) => asset.uid === key)?.uploadedAssetUrl ?? ''
    );
  };

  useEffect(() => {
    dispatch(
      getPublicAssetsThunk({
        uidIn: [...Object.values(publicAssetsListForLpChargeSerialCodePage)],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (serialCodeEndAt) {
      // クエリパラメータ取得時に+がスペースに変換されてしまうため、元に戻す
      const endAt = new Date(serialCodeEndAt.replace(' ', '+')).getTime();
      const now = new Date().getTime();

      if (endAt < now) {
        setIsSerialCodeOutOfPeriod(true);
      }
    }
  }, [dispatch, serialCodeEndAt]);

  return (
    <Presenter
      url={null}
      origin={null}
      referer={null}
      convertToPoint={null}
      destination={null}
      isSerialCodeOutOfPeriod={isSerialCodeOutOfPeriod}
      serialCode={serialCode}
      serialCodeEndAt={serialCodeEndAt}
      publicAssetsImage={publicAssetsImage}
    />
  );
};

export { Container as LpChargeSerialCodePage };
