import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Container, Link, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HeroSection } from './heroSection/Index';
import { AboutSection } from '/@/components/shared/lp/aboutSection/Index';
import { BenefitsOfConvertSection } from '/@/components/shared/lp/benefitsOfConvertSection/Index';
import { HowToUseSection } from '/@/components/shared/lp/howToUseSection/Index';
import { CampaignSection } from '/@/components/shared/lp/campaignSection/Index';
import { CautionText } from '/@/components/shared/cautionText/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import { Footer } from '/@/components/templates/footer/Index';
import { PATHS } from '/@/routes/paths';
import { LP_TYPES } from '/@/components/shared/lp/types';
import { neoPublicAssetsListForLpConvertToPointPage } from '/@/utils/constPublicAssetsList';
import { Sticky } from '/@/components/shared/sticky/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { theme } from '/@/utils/theme';

const StyledContainer = styled(Container)({
  padding: 0,
});
const StyledSection = styled('section')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 2),
  paddingTop: theme.spacing(3),
  '& + &': {
    paddingTop: theme.spacing(4),
  },
}));
const StyledLoginButton = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledCautionTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledStickyContainer = styled('div')({
  display: 'flex',
});
const StyledStickyNoteContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(3),
  color: theme.palette.secondary.dark,
}));
const StyledStickyLoginButtonContainer = styled('div')({
  minWidth: '200px',
});

type Props = LoginOption & {
  publicAssetsImage: (key: string) => string;
};

const Presenter: React.FC<Props> = ({
  url,
  origin,
  referer,
  convertToPoint,
  destination,
  publicAssetsImage,
}) => (
  <Grid container direction="column" alignContent="center">
    <StyledContainer maxWidth="sm">
      <StyledSection>
        <HeroSection
          url={url}
          origin={origin}
          referer={referer}
          convertToPoint={convertToPoint}
          destination={destination}
        />
      </StyledSection>
      <StyledSection>
        <AboutSection lpType={LP_TYPES.LP_CONVERT_TO_POINT} />
      </StyledSection>
      <StyledSection>
        <CampaignSection
          highReturnBrandsImageURL={publicAssetsImage(
            neoPublicAssetsListForLpConvertToPointPage.HIGH_RETURN_BRANDS_IMAGE,
          )}
          exchangeableBrandsImageURL={publicAssetsImage(
            neoPublicAssetsListForLpConvertToPointPage.EXCHANGEABLE_BRANDS_IMAGE,
          )}
        >
          <StyledLoginButton>
            <LoginButton
              url={url}
              origin={origin}
              referer={referer}
              convertToPoint={convertToPoint}
              destination={destination}
              serialCode={null}
              flow={PATHS.LP_CONVERT_TO_POINT}
              isDisplayNoteForPointConvert
            >
              ギフトをポイントに移行する
            </LoginButton>
          </StyledLoginButton>
        </CampaignSection>
      </StyledSection>
      <StyledSection>
        <BenefitsOfConvertSection
          lpType={LP_TYPES.LP_CONVERT_TO_POINT}
          subsection1ImageURL={publicAssetsImage(
            neoPublicAssetsListForLpConvertToPointPage.BENEFITS_OF_CONVERT_SECTION_SUBSECTION1,
          )}
          subsection3ImageURL={publicAssetsImage(
            neoPublicAssetsListForLpConvertToPointPage.BENEFITS_OF_CONVERT_SECTION_SUBSECTION3,
          )}
        />
      </StyledSection>
      <StyledSection>
        <HowToUseSection />
      </StyledSection>
      <StyledSection>
        <LoginButton
          url={url}
          origin={origin}
          referer={referer}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={null}
          flow={PATHS.LP_CONVERT_TO_POINT}
          isDisplayNoteForPointConvert
        >
          ギフトをポイントに移行する
        </LoginButton>
        <StyledCautionTextContainer>
          <CautionText />
        </StyledCautionTextContainer>
      </StyledSection>
      <Box mt={4} />
      <Footer />
      <Sticky>
        <StyledStickyContainer>
          <StyledStickyNoteContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
              <Link
                component={RouterLink}
                to={PATHS.TERMS_OF_SERVICE}
                style={{
                  color: theme.palette.secondary.dark,
                  textDecorationColor: theme.palette.secondary.dark,
                }}
              >
                サービス利用規約
              </Link>
              に同意したことになります
            </BaseTypography>
          </StyledStickyNoteContainer>
          <StyledStickyLoginButtonContainer>
            <LoginButton
              url={url}
              origin={origin}
              referer={referer}
              convertToPoint={convertToPoint}
              destination={destination}
              serialCode={null}
              flow={PATHS.LP_CONVERT_TO_POINT}
              isDisplayButtonOnly
            >
              ポイントに移行する
            </LoginButton>
          </StyledStickyLoginButtonContainer>
        </StyledStickyContainer>
      </Sticky>
    </StyledContainer>
  </Grid>
);

export { Presenter };
