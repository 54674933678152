import React from 'react';
import { Typography, Modal, Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MyGiftsDiscardConfirmationModalOpenState } from '/@/store/ui/myGiftsDiscardConfirmationModalOpen';
import { SelectedMyGiftsState } from '/@/store/app/selectedMyGifts';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { theme } from '/@/utils/theme';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: theme.breakpoints.values.sm,
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(),
  padding: theme.spacing(3),
  outline: 'none',
  textAlign: 'center',
}));
const StyledConfirmation = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
const StyledDiscardingListContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(1),
  textAlign: 'left',
}));
const StyledDiscardingList = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingLeft: theme.spacing(4),
  '& li::marker': {
    color: theme.palette.secondary.dark,
  },
}));
const StyledDiscardingMyGift = styled('p')({
  margin: 0,
  color: theme.palette.secondary.dark,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
});
const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  display: 'inline-block',
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));
const StyledConfirmButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));
const StyledCancelButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
}));

type Props = {
  myGiftsDiscardConfirmationModalOpen: MyGiftsDiscardConfirmationModalOpenState;
  handleMyGiftsDiscardConfirmationModalOpen: () => void;
  typicalSelectedMyGifts: SelectedMyGiftsState;
  otherSelectedMyGifts: SelectedMyGiftsState;
  handleDiscardMyGifts: () => void;
  isDiscardMyGiftsLoading: boolean;
};

const Presenter: React.FC<Props> = ({
  myGiftsDiscardConfirmationModalOpen,
  handleMyGiftsDiscardConfirmationModalOpen,
  typicalSelectedMyGifts,
  otherSelectedMyGifts,
  handleDiscardMyGifts,
  isDiscardMyGiftsLoading,
}) => (
  <Modal
    open={myGiftsDiscardConfirmationModalOpen}
    onClose={handleMyGiftsDiscardConfirmationModalOpen}
  >
    <StyledBox>
      <BaseTypography
        fontType={FONT_TYPE.HEADLINE}
        fontSize={FONT_SIZE.SM}
        isBold
      >
        ギフトを利用済みにしますか？
      </BaseTypography>
      <StyledConfirmation>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
          「利用済み」にするとマイギフトへは戻せません。
          <br />
          以下のギフトを「利用済み」にしますか？
        </BaseTypography>
      </StyledConfirmation>
      <StyledDiscardingListContainer>
        <StyledDiscardingList>
          {typicalSelectedMyGifts.map((myGift) => (
            <li key={myGift.urlCode}>
              <StyledDiscardingMyGift key={myGift.urlCode}>
                <BaseTypography
                  fontType={FONT_TYPE.BODY}
                  fontSize={FONT_SIZE.SM}
                >
                  {myGift.contentName}
                </BaseTypography>
              </StyledDiscardingMyGift>
            </li>
          ))}
          {otherSelectedMyGifts.length ? (
            <li>
              <StyledDiscardingMyGift>
                <BaseTypography
                  fontType={FONT_TYPE.BODY}
                  fontSize={FONT_SIZE.SM}
                >
                  その他 {otherSelectedMyGifts.length}件
                </BaseTypography>
              </StyledDiscardingMyGift>
            </li>
          ) : null}
        </StyledDiscardingList>
      </StyledDiscardingListContainer>
      <StyledDescription>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
          「利用済み」にしたギフトはマイギフトの「利用済み・期限切れ」タブで確認できます。
        </BaseTypography>
      </StyledDescription>
      <Grid container justifyContent="center">
        <StyledConfirmButtonContainer>
          <BaseButton
            onClick={() => handleDiscardMyGifts()}
            disabled={false}
            loading={isDiscardMyGiftsLoading}
            data-gtm-click="giftsPage-editPage-discardConfirmationModal-submitButton"
          >
            利用済みにする
          </BaseButton>
        </StyledConfirmButtonContainer>
        <StyledCancelButtonContainer>
          <BaseButton
            onClick={() => handleMyGiftsDiscardConfirmationModalOpen()}
            isTransparent
            data-gtm-click="giftsPage-editPage-discardConfirmationModal-cancelButton"
          >
            キャンセル
          </BaseButton>
        </StyledCancelButtonContainer>
      </Grid>
    </StyledBox>
  </Modal>
);

export { Presenter };
