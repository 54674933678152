import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getPointWalletThunk } from '/@/store/api/queries/getPointWallet';
import { useParams } from 'react-router-dom';
import { setItemSearchCondition } from '/@/store/app/itemSearchCondition/actions';
import { itemSearchConditionActions } from '/@/store/app/itemSearchCondition';

const Container: React.FC<Record<string, never>> = () => {
  const headerTitle = 'デジタルチケット一覧';

  const { minPoint, maxPoint } = useParams();
  const dispatch = useAppDispatch();
  const itemSearchCondition = useAppSelector(
    (state) => state.app.itemSearchCondition,
  );
  const point = useAppSelector((state) => state.app.pointWallet?.point);

  useEffect(() => {
    if (point === undefined) {
      dispatch(getPointWalletThunk());
    }
  }, [dispatch, point]);

  useEffect(() => {
    dispatch(
      itemSearchConditionActions.setItemSearchCondition({
        brandUid: undefined,
        minPoint: parseInt(minPoint || '1'),
        maxPoint: maxPoint ? parseInt(maxPoint) : point,
      }),
    );
  }, [dispatch, point, maxPoint, minPoint]);

  useEffect(() => {
    dispatch(getPointWalletThunk()).catch(() => {});
    if (itemSearchCondition === null && point !== undefined) {
      const newCondition = {
        minPoint: parseInt(minPoint || '1'),
        maxPoint: maxPoint ? parseInt(maxPoint) : point,
      };
      dispatch(setItemSearchCondition(newCondition));
    }
  }, [dispatch, point, itemSearchCondition, minPoint, maxPoint]);

  return <Presenter headerTitle={headerTitle} />;
};

export { Container as PointBrandsPage };
