import React, { useCallback, useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { giftConvertToPointRequestSubscriptionThunk } from '/@/store/api/subscriptions/giftConvertToPointRequestSubscription';
import { useAppDispatch, useAppSelector } from '/@/store';
import {
  createGiftConvertToPointInitThunk,
  createGiftConvertToPointThunk,
} from '/@/store/api/mutations/createGiftConvertToPoint';
import createRequestUid from '/@/utils/createRequestUid';
import { initialiseGiftConvertToPointRequestSubscriptionStatus } from '/@/store/api/subscriptions/giftConvertToPointRequestSubscription/actions';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { STATUS } from '/@/store/api/constants';
import { getGiftConvertToPointRequestThunk } from '/@/store/api/queries/getGiftConvertToPointRequest';
import { setAlertMessage } from '/@/store/ui/alertMessage/actions';
import * as Sentry from '@sentry/browser';

type OwnProps = {
  handleClose: () => void;
  skipConfirm?: boolean;
};

const pollingInterval = 5000;
const pollingFrequencyLimit = 12;
const ERROR_MESSAGES = {
  FAILURE: 'ポイント移行に失敗しました。時間をおいて再度お試しください。',
  TIMEOUT:
    'ポイント移行に時間がかかっています。時間をおいてポイント履歴をご確認ください。',
};

const Container: React.FC<OwnProps> = ({ handleClose, skipConfirm }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const requestUid = createRequestUid();
  const { pushClickEvent, pushPointChargeEvent } = useDataLayer();
  const createGiftConvertToPointStatus = useAppSelector(
    (state) => state.api.createGiftConvertToPoint.status,
  );
  const giftConvertToPointRequestSubscriptionStatus = useAppSelector(
    (state) => state.api.giftConvertToPointRequestSubscription.status,
  );
  const myGift = useAppSelector((state) => state.app.myGift);
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const cancelCharge = () => {
    pushClickEvent({
      clickItem: 'cancelChargeButton',
      customData: {
        clickItem: 'cancelChargeButton',
        itemName: myGift?.contentName,
        point: myGift?.remainingPoint,
      },
    });
    handleClose();
  };

  // ポイント移行状況のポーリング処理
  const pollingGiftConvertToPointRequest = useCallback(() => {
    let pollingCount = 0;

    // ポーリング終了処理
    const finishPolling = (navigateToCompletion = false) => {
      clearInterval(intervalId);
      setIsPolling(false);
      handleClose();

      if (navigateToCompletion) {
        navigate(PATHS.POINT_CHARGE_COMPLETION);
      }
    };

    // ポーリング開始
    const intervalId = setInterval(() => {
      pollingCount++;

      // ポーリングリクエスト実行
      dispatch(
        getGiftConvertToPointRequestThunk({
          requestUid: requestUid,
        }),
      ).then((response) => {
        // 移行完了
        if (
          response?.data?.giftConvertToPointRequest?.myGift.pointMergeable ===
          false
        ) {
          finishPolling(true); // 完了画面へ遷移
        }

        // 移行未完了＆ポーリング上限到達時
        else if (pollingCount >= pollingFrequencyLimit) {
          finishPolling();
          dispatch(
            setAlertMessage({
              message: ERROR_MESSAGES.TIMEOUT,
              severity: 'error',
            }),
          );
          Sentry.captureException(
            `getGiftConvertToPointRequest warning: Polling limit exceeded after ${pollingCount} attempts`,
          );
        }

        // APIエラー時
        else if (!response) {
          // ポーリング失敗
          finishPolling();
          dispatch(
            setAlertMessage({
              message: ERROR_MESSAGES.FAILURE,
              severity: 'error',
            }),
          );
          Sentry.captureException(
            new Error(
              'getGiftConvertToPointRequest error: Failed to get response',
            ),
          );
        }
      });
    }, pollingInterval);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [dispatch, navigate, handleClose, requestUid]);

  // ポイント移行処理
  const handleSubmit = useCallback(() => {
    if (
      myGift?.urlCode &&
      myGift?.remainingPoint &&
      createGiftConvertToPointStatus === STATUS.IDLE
    ) {
      // 1. ポイント移行処理
      dispatch(
        createGiftConvertToPointThunk({
          myGiftUrlCode: myGift.urlCode,
          requestUid: requestUid,
          convertPointAmount: myGift.remainingPoint,
        }),
      ).then(() => {
        pushPointChargeEvent({
          itemName: myGift?.contentName,
          point: myGift?.remainingPoint,
          customData: {
            itemName: myGift?.contentName,
            point: myGift?.remainingPoint,
          },
        });

        // 2. サブスクリプションによる結果取得を開始
        dispatch(
          giftConvertToPointRequestSubscriptionThunk({
            requestUid: requestUid,
          }),
        );

        // 3. バックアップ対応としてポーリングも開始
        setIsPolling(true);
        // 少し遅延させてポーリングを開始
        const pollingCleanup = pollingGiftConvertToPointRequest();

        // コンポーネントアンマウント時のクリーンアップを保存
        return () => {
          if (pollingCleanup) pollingCleanup();
        };
      });
    }
  }, [
    dispatch,
    myGift,
    createGiftConvertToPointStatus,
    requestUid,
    pushPointChargeEvent,
    pollingGiftConvertToPointRequest,
  ]);

  useEffect(() => {
    if (myGift?.urlCode && myGift?.remainingPoint) {
      dispatch(createGiftConvertToPointInitThunk());
    }
  }, [dispatch, myGift]);

  useEffect(() => {
    if (skipConfirm === true && myGift?.remainingPoint) {
      handleSubmit();
    }
  }, [dispatch, myGift, skipConfirm, handleSubmit]);

  useEffect(() => {
    // MEMO: subscriptionにて移行成功通知取得時に画面遷移
    if (giftConvertToPointRequestSubscriptionStatus === STATUS.SUCCESS) {
      dispatch(initialiseGiftConvertToPointRequestSubscriptionStatus());
      navigate(PATHS.POINT_CHARGE_COMPLETION);
    } else if (giftConvertToPointRequestSubscriptionStatus === STATUS.FAILURE) {
      dispatch(initialiseGiftConvertToPointRequestSubscriptionStatus());
      handleClose();
      // MEMO:移行時エラーのalertMessageがページTOPに表示されるため
      window.scrollTo(0, 0);
    }
  }, [
    giftConvertToPointRequestSubscriptionStatus,
    dispatch,
    navigate,
    handleClose,
  ]);

  return myGift?.remainingPoint && !skipConfirm ? (
    <Presenter
      cancelCharge={cancelCharge}
      handleSubmit={handleSubmit}
      pointAmount={myGift.remainingPoint}
      isLoading={
        createGiftConvertToPointStatus === STATUS.LOADING ||
        giftConvertToPointRequestSubscriptionStatus === STATUS.LOADING ||
        isPolling
      }
    />
  ) : null;
};
export { Container as PointChargeModal };
