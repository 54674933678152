import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormLabelProps,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, MouseEventHandler } from 'react';
import { SignUpUserInput } from '../types';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { genderCodes } from '/@/utils/genderCodeMapping';
import { prefectureCodes } from '/@/utils/prefectureCodeMapping';
import { theme } from '/@/utils/theme';

type Props = {
  isLoading: boolean;
  pushDatalayer: () => void;
  signUpUserInput: SignUpUserInput;
  handleChangeGender: (selectedGenderCode: string) => void;
  handleChangeBirthdayYear: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthdayMonth: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthdayDay: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangePrefecture: (event: SelectChangeEvent<string | null>) => void;
  handleClickBackButton: MouseEventHandler<HTMLButtonElement>;
};
const StyledSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  textAlign: 'center',
}));
const StyledFormGroup = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(),
}));
const StyledFormTitleLabel = styled(FormLabel)<FormLabelProps>({
  color: theme.palette.grey[700],
});
const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme, checked }) => ({
    color: checked ? theme.palette.common.white : theme.palette.secondary.dark,
    backgroundColor: checked
      ? theme.palette.common.black
      : theme.palette.background.default,
    border: '2px solid ' + theme.palette.grey[400],
    borderRadius: theme.spacing(3),
    width: 'calc((100% - 16px) / 3)',
    maxWidth: '120px',
    marginRight: 0,
  }),
);
const StyledFormContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  '& + &': {
    marginTop: theme.spacing(2),
  },
}));

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledBirthDayTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: '2px solid ' + theme.palette.grey[400],
  borderRadius: theme.spacing(1),
  fieldset: {
    border: 'none',
  },
}));
const StyledDateText = styled(Typography)({
  marginTop: 'auto',
  color: theme.palette.grey[700],
});

const Presenter: React.FC<Props> = ({
  isLoading,
  pushDatalayer,
  signUpUserInput,
  handleChangeGender,
  handleChangeBirthdayYear,
  handleChangeBirthdayMonth,
  handleChangeBirthdayDay,
  handleChangePrefecture,
  handleClickBackButton,
}) => (
  <StyledSection maxWidth="sm">
    <StyledTitleContainer>
      <BaseTypography
        fontType={FONT_TYPE.HEADLINE}
        fontSize={FONT_SIZE.LG}
        isBold
      >
        基本情報の入力
      </BaseTypography>
    </StyledTitleContainer>
    <Grid container direction="column" alignItems="stretch" spacing={4}>
      <StyledFormContainer>
        <FormControl component="fieldset" fullWidth>
          <StyledFormTitleLabel component="legend">性別</StyledFormTitleLabel>
          <Box mt={2}>
            <StyledRadioGroup
              row
              value={signUpUserInput.genderCode}
              onChange={(e) => handleChangeGender(e.target.value)}
            >
              {genderCodes.map((genderCodeMaster, index) => (
                <StyledFormControlLabel
                  key={index}
                  value={String(genderCodeMaster.code)}
                  control={<Radio />}
                  label={genderCodeMaster.name}
                  data-gtm-click="signUpPage-genderSelectionButton"
                />
              ))}
            </StyledRadioGroup>
          </Box>
        </FormControl>
      </StyledFormContainer>
      <StyledFormContainer>
        <FormControl component="fieldset">
          <StyledFormTitleLabel component="legend">
            生年月日
          </StyledFormTitleLabel>
          <StyledFormGroup
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid size="grow">
              <StyledBirthDayTextField
                id="user-profile-birth-year"
                variant="outlined"
                size="small"
                required
                value={signUpUserInput.birthdayYear}
                autoComplete="bday-year"
                onChange={handleChangeBirthdayYear}
                slotProps={{
                  htmlInput: {
                    inputMode: 'numeric',
                    style: { textAlign: 'center' },
                    maxLength: 4,
                    minLength: 4,
                    pattern: '^(19|20)\\d{2}$',
                  },
                }}
              />
            </Grid>
            <StyledDateText variant="caption">年</StyledDateText>
            <Grid size="grow">
              <StyledBirthDayTextField
                id="user-profile-birth-month"
                variant="outlined"
                size="small"
                required
                value={signUpUserInput.birthdayMonth}
                autoComplete="bday-month"
                onChange={handleChangeBirthdayMonth}
                slotProps={{
                  htmlInput: {
                    inputMode: 'numeric',
                    style: { textAlign: 'center' },
                    maxLength: 2,
                    minLength: 1,
                    pattern: '^(0?[1-9]|1[012])$',
                  },
                }}
              />
            </Grid>
            <StyledDateText variant="caption">月</StyledDateText>
            <Grid size="grow">
              <StyledBirthDayTextField
                id="user-profile-birth-day"
                variant="outlined"
                size="small"
                required
                value={signUpUserInput.birthdayDay}
                autoComplete="bday-day"
                onChange={handleChangeBirthdayDay}
                slotProps={{
                  htmlInput: {
                    inputMode: 'numeric',
                    style: { textAlign: 'center' },
                    maxLength: 2,
                    minLength: 1,
                    pattern: '^(0?[1-9]|[12]\\d|3[01])$',
                  },
                }}
              />
            </Grid>
            <StyledDateText variant="caption">日</StyledDateText>
          </StyledFormGroup>
        </FormControl>
      </StyledFormContainer>
      <StyledFormContainer>
        <FormControl fullWidth>
          <StyledFormTitleLabel id="user-profile-prefecture-label">
            お住まいの地域
          </StyledFormTitleLabel>
          <Select
            required
            labelId="user-profile-prefecture-label"
            id="user-profile-prefecture"
            value={signUpUserInput.prefectureCode || ''}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <Typography color="textSecondary">
                    選択してください
                  </Typography>
                );
              }
              return prefectureCodes.find(
                (prefectureCode) => prefectureCode.code === Number(selected),
              )?.name;
            }}
            onChange={handleChangePrefecture}
          >
            <MenuItem value="">選択してください</MenuItem>
            {prefectureCodes.map((prefectureCodeMaster) => (
              <MenuItem
                key={prefectureCodeMaster.code}
                value={prefectureCodeMaster.code}
              >
                {prefectureCodeMaster.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledFormContainer>
      <Grid size="grow" container spacing={2}>
        <BaseButton
          disableElevation
          variant="contained"
          fullWidth
          type="submit"
          loading={isLoading}
          disabled={
            !signUpUserInput.genderCode ||
            !signUpUserInput.birthdayYear ||
            !signUpUserInput.birthdayMonth ||
            !signUpUserInput.birthdayDay ||
            !signUpUserInput.prefectureCode
          }
          onClick={pushDatalayer}
        >
          作成
        </BaseButton>
      </Grid>
      <Grid size="grow" container spacing={2}>
        <BaseButton
          disableElevation
          variant="contained"
          fullWidth
          type="button"
          isTransparent
          onClick={handleClickBackButton}
        >
          戻る
        </BaseButton>
      </Grid>
    </Grid>
  </StyledSection>
);

export { Presenter };
