import React from 'react';
import { Presenter } from './Presenter';

export type Detail = { key: string; value: string };
type Props = {
  title: string;
  details?: Detail[];
  point: number;
};

const Container: React.FC<Props> = ({ title, details, point }) => {
  return <Presenter title={title} details={details} point={point} />;
};

export { Container as PointLog };
