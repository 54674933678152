import React from 'react';
import { Presenter } from './Presenter';

type OwnProps = {
  handleClose: () => void;
};

const Container: React.FC<OwnProps> = ({ handleClose }) => {
  return <Presenter handleClose={handleClose} />;
};
export { Container as BenefitOfPointChargeModal };
