import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { CreatePointExchangeMutation } from '/@/api/graphql/internalApi/types';
import { POINT_FONT_SIZE, Points } from '/@/components/shared/points/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import { BaseDivider } from '/@/components/shared/divider/Index';

const StyledMyGiftCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  border: 'none',
}));
const StyledLogoAndTitle = styled('div')({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  margin: 'auto',
  height: '100%',
  width: '100%',
});
const StyledLogoContainer = styled('div')(({ theme }) => ({
  width: '64px',
  height: '64px',
  marginTop: 'auto',
  marginBottom: 'auto',
  borderRadius: theme.spacing(1),
  border: '2px solid ' + theme.palette.secondary.light,
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: 'auto',
  maxWidth: `calc(100% - ${theme.spacing(8)} - ${theme.spacing(1)})`, // MEMO: 左のコンテンツ幅とマージン分を引く
}));
const StyledBrandName = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: '0.625rem',
  lineHeight: '1',
  color: theme.palette.grey[700],
}));
const StyledItemName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.875rem',
  lineHeight: '1.4rem',
  fontWeight: 'bold',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
}));
const StyledDividerContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
const StyledPointBackContent = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(3, 0),
  color: theme.palette.secondary.dark,
  backgroundColor: '#FDF2F2',
  borderRadius: theme.spacing(2),
  textAlign: 'center',
}));
const StyledPointText = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'inline-flex',
}));
const StyledPointBackText = styled(Typography)(({ theme }) => ({
  marginTop: 'auto',
  marginLeft: theme.spacing(0.5),
  fontSize: '0.75rem',
  lineHeight: '2rem',
  color: theme.palette.secondary.dark,
}));
const StyledUsingGiftButtonContainer = styled('div')({
  width: '100%',
});
const StyledNavigateToHomeButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
}));
const StyledNote = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  textAlign: 'center',
}));

type Props = {
  exchangedGifts: CreatePointExchangeMutation['createPointExchange']['pointExchange'];
  navigateToHome: () => void;
};
const Presenter: React.FC<Props> = ({ exchangedGifts, navigateToHome }) => (
  <StyledMyGiftCard variant="outlined">
    {exchangedGifts?.myGifts.map((myGift, index) => (
      <div key={index}>
        {index > 0 && (
          <StyledDividerContainer>
            <BaseDivider />
          </StyledDividerContainer>
        )}
        <StyledLogoAndTitle>
          <StyledLogoContainer>
            <StyledLogo src={myGift.imageUrl} alt={myGift.contentName} />
          </StyledLogoContainer>
          <StyledRightContent>
            <StyledBrandName>{exchangedGifts.item.brand.name}</StyledBrandName>
            <StyledItemName>{myGift.contentName}</StyledItemName>
          </StyledRightContent>
        </StyledLogoAndTitle>
      </div>
    ))}
    {exchangedGifts?.pointBackCampaignApply && (
      <StyledPointBackContent>
        <BaseTypography
          fontType={FONT_TYPE.BODY}
          fontSize={FONT_SIZE.MD}
          isBold
        >
          獲得した還元ポイント
        </BaseTypography>
        <StyledPointText>
          <Points
            points={exchangedGifts.pointBackCampaignApply.backPoint}
            fontSize={POINT_FONT_SIZE.LG}
          />
          <StyledPointBackText>
            {`(${exchangedGifts.pointBackCampaignApply.pointBackCampaign.discountPercentage}%還元)`}
          </StyledPointBackText>
        </StyledPointText>
      </StyledPointBackContent>
    )}
    <StyledDividerContainer>
      <BaseDivider />
    </StyledDividerContainer>
    <Grid container direction="column" alignContent="center">
      {exchangedGifts?.myGifts[0].giftUrl && (
        <StyledUsingGiftButtonContainer>
          <BaseButton
            disableElevation
            component={Link}
            to={exchangedGifts?.myGifts[0].giftUrl}
          >
            ギフトを使用する
          </BaseButton>
        </StyledUsingGiftButtonContainer>
      )}
      <StyledNavigateToHomeButtonContainer>
        <BaseButton
          isTransparent
          disableElevation
          onClick={navigateToHome}
          data-cy="usingGiftButton"
        >
          マイギフトをみる
        </BaseButton>
      </StyledNavigateToHomeButtonContainer>
      <StyledNote>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
          交換したギフトはマイギフトより確認できます
        </BaseTypography>
      </StyledNote>
    </Grid>
  </StyledMyGiftCard>
);

export { Presenter };
