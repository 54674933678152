import React from 'react';
import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { formatToDateAndTimeJa } from '/@/utils/formatDate';
import ArrowRightIcon from '/@/assets/shared/icon/neo-arrow-right-icon.svg';
import { AnnouncementItemType } from './Index';
import { UnreadBadge } from '/@/components/shared/badge/unreadBadge/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';

const StyledCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));
const StyledCardContent = styled('div')({
  display: 'flex',
  minWidth: 0,
  width: '100%',
  textAlign: 'left',
});
const StyledTitleAndCreatedAtContainer = styled('div')({
  width: 'auto',
});
const StyledTitleContainer = styled('div')(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  color: theme.palette.secondary.dark,
}));
const StyledCreatedAtContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));
const StyledArrowRightIcon = styled('img')({});
const StyledUnreadBadge = styled('div')(({ theme }) => ({
  margin: 'auto 0',
  marginRight: theme.spacing(1),
}));

type Props = {
  announcement: AnnouncementItemType;
  navigateAnnouncementDetail: () => void;
};

const Presenter: React.FC<Props> = ({
  announcement,
  navigateAnnouncementDetail,
}) => (
  <StyledCard onClick={navigateAnnouncementDetail}>
    <Grid container wrap="nowrap" direction="row">
      <StyledCardContent>
        {announcement.readAt === null && (
          <StyledUnreadBadge>
            <UnreadBadge />
          </StyledUnreadBadge>
        )}
        <StyledTitleAndCreatedAtContainer>
          <StyledTitleContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              {announcement.title}
            </BaseTypography>
          </StyledTitleContainer>
          <StyledCreatedAtContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              {formatToDateAndTimeJa(announcement.createdAt)}
            </BaseTypography>
          </StyledCreatedAtContainer>
        </StyledTitleAndCreatedAtContainer>
      </StyledCardContent>
      <Grid
        size={{ xs: 3 }}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
      </Grid>
    </Grid>
  </StyledCard>
);

export { Presenter };
