import { gql } from '@apollo/client';

export const createUserRegistrationSessionGql = gql`
  mutation CreateUserRegistrationSession(
    $createUserRegistrationSessionInput: CreateUserRegistrationSessionInput!
  ) {
    createUserRegistrationSession(input: $createUserRegistrationSessionInput) {
      success
    }
  }
`;
