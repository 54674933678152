import React, { ReactNode } from 'react';
import { Presenter } from './Presenter';

type OwnProps = {
  children: ReactNode;
  validationMessage: string | null;
};

const Container: React.FC<OwnProps> = ({ children, validationMessage }) => {
  return (
    <Presenter validationMessage={validationMessage}>{children}</Presenter>
  );
};

export { Container as FormFieldWithValidation };
