import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getExpiringMyGiftsConnectionThunk } from '/@/store/api/queries/getMyGiftsConnection';
import { Presenter } from './Presenter';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { STATUS } from '/@/store/api/constants';

const Container: React.FC<Record<string, never>> = () => {
  const expiredMyGifts = useAppSelector(
    (state) => state.app.expiringMyGiftsConnection.myGifts,
  );
  const expiringMyGiftsConnectionpageInfo = useAppSelector(
    (state) => state.app.expiringMyGiftsConnection.pageInfo,
  );
  const getExpiringMyGiftsConnectionStatus = useAppSelector(
    (state) => state.app.expiringMyGiftsConnection.status,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getExpiringMyGiftsConnectionThunk()).catch(() => {});
  }, [dispatch]);

  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      getExpiringMyGiftsConnectionStatus !== STATUS.LOADING &&
      expiringMyGiftsConnectionpageInfo?.hasNextPage
    ) {
      dispatch(
        getExpiringMyGiftsConnectionThunk(
          expiringMyGiftsConnectionpageInfo.endCursor,
        ),
      ).catch(() => {});
    }
  }, [
    dispatch,
    isIntersect,
    getExpiringMyGiftsConnectionStatus,
    expiredMyGifts,
    expiringMyGiftsConnectionpageInfo,
  ]);

  return (
    <Presenter
      expiredMyGifts={expiredMyGifts}
      infiniteScrollTarget={infiniteScrollTarget}
      getExpiringMyGiftsConnectionStatus={getExpiringMyGiftsConnectionStatus}
    />
  );
};

export { Container as ExpiringMyGifts };
