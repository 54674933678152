import React from 'react';
import ArrowLeftIcon from '/@/assets/shared/icon/neo-arrow-left-icon.svg';
import { styled } from '@mui/material/styles';
import { IconButton, Typography } from '@mui/material';

const StyledArrowBackIcon = styled(IconButton)({
  padding: 0,
});
const StyledTitle = styled(Typography)({
  fontWeight: 'bold',
});

type Props = {
  headerTitle: string;
  handleNavigateBack: () => void;
};

const Presenter: React.FC<Props> = ({ headerTitle, handleNavigateBack }) => (
  <>
    <StyledArrowBackIcon
      color="secondary"
      onClick={handleNavigateBack}
      data-gtm-click="templates-header-handleNavigateBackButton"
    >
      <img src={ArrowLeftIcon} alt="戻るボタン" />
    </StyledArrowBackIcon>
    <StyledTitle>{headerTitle}</StyledTitle>
  </>
);

export { Presenter };
