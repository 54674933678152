import React from 'react';
import { RecommendedContents } from './recommendedContents/Index';
import { ExchangeableContents } from './exchangeableContents/Index';
import { Button, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { DisplayOnlyExchangeableBrandsState } from '/@/store/app/displayOnlyExchangeableBrands';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../typography/Index';
import checkboxCheckedIcon from '/@/assets/shared/icon/checkbox-checked-icon.svg';
import checkboxNotcheckedIcon from '/@/assets/shared/icon/checkbox-notchecked-icon.svg';

const StyledSwitchDisplayOnlyExchangeableContainer = styled(Button)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    width: '100%',
    color: theme.palette.secondary.dark,
  }),
);
const StyledSectionTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  '&:not(:first-child)': {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  isDisplayOnlyExchangeableBrands: DisplayOnlyExchangeableBrandsState;
  handleDisplayOnlyExchangeable: () => void;
};
const Presenter: React.FC<Props> = ({
  isDisplayOnlyExchangeableBrands,
  handleDisplayOnlyExchangeable,
}) => (
  <>
    <StyledSwitchDisplayOnlyExchangeableContainer
      onClick={handleDisplayOnlyExchangeable}
    >
      <BaseTypography
        fontType={FONT_TYPE.HEADLINE}
        fontSize={FONT_SIZE.MD}
        isBold
      >
        チケットを選択
      </BaseTypography>
      <Box sx={{ display: 'flex' }}>
        <Checkbox
          checked={isDisplayOnlyExchangeableBrands}
          style={{ padding: 0, marginRight: 4 }}
          icon={
            <img src={checkboxNotcheckedIcon} alt="checkboxNotcheckedIcon" />
          }
          checkedIcon={
            <img src={checkboxCheckedIcon} alt="checkboxCheckedIcon" />
          }
        />
        <BaseTypography fontType={FONT_TYPE.HEADLINE} fontSize={FONT_SIZE.SM}>
          交換可能なチケットに絞る
        </BaseTypography>
      </Box>
    </StyledSwitchDisplayOnlyExchangeableContainer>
    <StyledSectionTitleContainer>
      <BaseTypography
        fontType={FONT_TYPE.HEADLINE}
        fontSize={FONT_SIZE.MD}
        isBold
      >
        今交換するとお得なチケット
      </BaseTypography>
    </StyledSectionTitleContainer>
    <RecommendedContents />
    <StyledSectionTitleContainer>
      <BaseTypography
        fontType={FONT_TYPE.HEADLINE}
        fontSize={FONT_SIZE.MD}
        isBold
      >
        交換可能なチケット
      </BaseTypography>
    </StyledSectionTitleContainer>
    <ExchangeableContents />
  </>
);

export { Presenter };
