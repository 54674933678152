import { Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { BaseButton } from '../../shared/button/baseButton/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '../../shared/typography/Index';
import { FrequentlyAskedQuestion } from '/@/api/graphql/publicApi/types';
import answerIcon from '/@/assets/shared/icon/answer-icon.svg';
import questionIcon from '/@/assets/shared/icon/question-icon.svg';
import { BaseDivider } from '/@/components/shared/divider/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import { getHtmlString } from '/@/utils/getHtmlString';
import { theme } from '/@/utils/theme';

const ICON_SIZE = '24px';

const StyledQAContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));
const StyledQAListContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 0),
}));
const StyledQuestionContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'left',
}));
const StyledQuestionIcon = styled('img')({
  width: ICON_SIZE,
  height: ICON_SIZE,
});
const StyledQuestionText = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginLeft: theme.spacing(1),
}));
const StyledAnswerContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  justifyContent: 'left',
}));
const StyledAnswerIcon = styled('img')({
  width: ICON_SIZE,
  height: ICON_SIZE,
});
const StyledAnswerText = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginLeft: theme.spacing(1),
}));
const StyledInquiryContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledInquiryTextContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textAlign: 'center',
}));
const StyledInquiryButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

type Props = {
  frequentlyAskedQuestions: FrequentlyAskedQuestion[] | null;
};

const Presenter: React.FC<Props> = ({ frequentlyAskedQuestions }) => (
  <MainLayout>
    <StyledQAContainer
      maxWidth="sm"
      data-testid="page-frequentlyAskedQuestions"
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="left"
        data-testid="frequentlyAskedQuestions"
      >
        <StyledTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.LG}
            isBold
          >
            よくあるご質問
          </BaseTypography>
        </StyledTitleContainer>
        <BaseDivider />
        {frequentlyAskedQuestions &&
          frequentlyAskedQuestions.map((frequentlyAskedQuestion, index) => (
            <React.Fragment key={index}>
              <StyledQAListContainer>
                <StyledQuestionContainer>
                  <StyledQuestionIcon src={questionIcon} alt="questionIcon" />
                  <StyledQuestionText>
                    <BaseTypography
                      fontType={FONT_TYPE.BODY}
                      fontSize={FONT_SIZE.MD}
                      isBold
                    >
                      {frequentlyAskedQuestion.title}
                    </BaseTypography>
                  </StyledQuestionText>
                </StyledQuestionContainer>
                <StyledAnswerContainer>
                  <StyledAnswerIcon src={answerIcon} alt="answerIcon" />
                  <StyledAnswerText
                    dangerouslySetInnerHTML={{
                      __html: getHtmlString(frequentlyAskedQuestion.answer),
                    }}
                  />
                </StyledAnswerContainer>
              </StyledQAListContainer>
              <BaseDivider />
            </React.Fragment>
          ))}
      </Grid>
    </StyledQAContainer>
    <StyledInquiryContainer maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        bgcolor={theme.palette.secondary.light}
        borderRadius={theme.spacing(2)}
        padding={theme.spacing(3, 2)}
      >
        <StyledInquiryTextContainer>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
            問題が解決しない場合やその他のご質問は
            <br />
            こちらからお問い合わせください。
          </BaseTypography>
        </StyledInquiryTextContainer>
        <StyledInquiryButtonContainer>
          <BaseButton
            data-testid="inquiriesNewPageButton"
            disableElevation
            component={Link}
            to={PATHS.INQUIRIES_NEW}
          >
            お問い合わせ
          </BaseButton>
        </StyledInquiryButtonContainer>
      </Grid>
    </StyledInquiryContainer>
  </MainLayout>
);

export { Presenter };
