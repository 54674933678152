import { styled } from '@mui/material';
import React, { FormEvent, useState } from 'react';
import { Control, FieldErrors, UseFormTrigger } from 'react-hook-form';
import { BasicInfo } from './basicInfo/Index';
import { LoginSection } from './loginSection/Index';
import { ProfileInfo } from './profileInfo/Index';
import { SignUpUserInput } from './types';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { MainLayout } from '/@/components/templates/mainLayout/Index';

type BasicInfoFormData = {
  email: string;
  password: string;
};

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  padding: theme.spacing(2),
}));

enum Page {
  BasicInfo = 'basicInfo',
  userProfile = 'userProfile',
}

type Props = {
  control: Control<BasicInfoFormData>;
  errors: FieldErrors<BasicInfoFormData>;
  isValid: boolean;
  trigger: UseFormTrigger<BasicInfoFormData>;
  isLoading: boolean;
  signUpUserInput: SignUpUserInput;
  setSignUpUserInput: React.Dispatch<React.SetStateAction<SignUpUserInput>>;
  pushDatalayer: () => void;
  serialCode: string | null;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

const Presenter: React.FC<Props> = ({
  control,
  errors,
  isValid,
  trigger,
  isLoading,
  signUpUserInput,
  setSignUpUserInput,
  pushDatalayer,
  serialCode,
  handleSubmit,
}) => {
  const { pushClickEvent } = useDataLayer();

  const [currentPage, setCurrentPage] = useState<Page>(Page.BasicInfo);
  const handleClickNextButton = () => {
    pushClickEvent({
      clickItem: 'signUpNextButton',
      customData: {
        clickItem: 'signUpNextButton',
      },
    });
    setCurrentPage(Page.userProfile);
  };
  const handleClickBackButton = () => {
    pushClickEvent({
      clickItem: 'signUpBackButton',
      customData: {
        clickItem: 'signUpBackButton',
      },
    });
    setCurrentPage(Page.BasicInfo);
  };

  return (
    <MainLayout>
      <StyledContainer>
        <form data-testid="sign-up-form" onSubmit={handleSubmit}>
          {currentPage == Page.BasicInfo ? (
            <BasicInfo
              control={control}
              errors={errors}
              isValid={isValid}
              trigger={trigger}
              signUpUserInput={signUpUserInput}
              setSignUpUserInput={setSignUpUserInput}
              handleClickNextButton={handleClickNextButton}
            />
          ) : (
            <ProfileInfo
              isLoading={isLoading}
              signUpUserInput={signUpUserInput}
              setSignUpUserInput={setSignUpUserInput}
              pushDatalayer={pushDatalayer}
              handleClickBackButton={handleClickBackButton}
            />
          )}
        </form>
        {currentPage == Page.BasicInfo && (
          <LoginSection
            url={null}
            origin={null}
            referer={null}
            serialCode={serialCode}
            convertToPoint={null}
            destination={null}
          />
        )}
      </StyledContainer>
    </MainLayout>
  );
};
export { Presenter };
