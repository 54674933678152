import React from 'react';
import { Card, CardActionArea, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PromotionSpotsState } from '/@/store/app/promotionSpots';
import { BaseLabel } from '../label/baseLabel';
import { theme } from '/@/utils/theme';
import { LABEL_SIZE } from '../label/baseLabel/Presenter';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../typography/Index';

const REWARD_SIZE = 112;
const PROMOTER_SIZE = 48;
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: theme.spacing(2),
  boxShadow: 'none',
  border: `2px solid ${theme.palette.secondary.light}`,
}));
const StyledRewardCardSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledRewardImage = styled('img')(({ theme }) => ({
  height: REWARD_SIZE,
  width: REWARD_SIZE,
  borderRadius: theme.spacing(),
}));
const StyledRightContentsContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  marginLeft: theme.spacing(2),
}));
const StyledRewardNameContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginTop: theme.spacing(1),
}));
const StyledPromoterCardSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
}));
const StyledPromoterImage = styled('img')(({ theme }) => ({
  height: PROMOTER_SIZE,
  width: PROMOTER_SIZE,
  marginRight: theme.spacing(1),
  borderRadius: theme.spacing(1),
}));
const StyledPromoterNameContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledPromotionNameContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.secondary.dark,
}));
const StyledPromotionDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.secondary.dark,
}));

type Props = {
  promotionSpot: NonNullable<PromotionSpotsState>[number];
};

const Presenter: React.FC<Props> = ({ promotionSpot }) => (
  <StyledCard>
    <CardActionArea
      href={promotionSpot.promotionPublishment.url}
      role="link"
      data-promotion-name={promotionSpot.promotionPublishment.promotion.name}
      data-promotion-spot-name={promotionSpot.name}
    >
      <StyledRewardCardSection container wrap="nowrap">
        <StyledRewardImage
          src={promotionSpot.promotionPublishment.promotion.reward.imageUrl}
          alt={promotionSpot.promotionPublishment.promotion.reward.name}
        />
        <StyledRightContentsContainer>
          <BaseLabel
            labelText={
              promotionSpot.promotionPublishment.promotion.promotionTag.name
            }
            labelSize={LABEL_SIZE.MEDIUM}
            color={'#1DABD6'}
            backgroundColor={theme.palette.background.contrast}
          />
          <StyledRewardNameContainer>
            <BaseTypography
              fontType={FONT_TYPE.BODY}
              fontSize={FONT_SIZE.MD}
              isBold
            >
              {promotionSpot.promotionPublishment.promotion.reward.name}
            </BaseTypography>
          </StyledRewardNameContainer>
        </StyledRightContentsContainer>
      </StyledRewardCardSection>
      <StyledPromoterCardSection container wrap="nowrap">
        <StyledPromoterImage
          src={promotionSpot.promotionPublishment.promotion.promoter.imageUrl}
          alt={promotionSpot.promotionPublishment.promotion.promoter.name}
        />
        <Grid container direction="column">
          <StyledPromoterNameContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              {promotionSpot.promotionPublishment.promotion.promoter.name}
            </BaseTypography>
          </StyledPromoterNameContainer>
          <StyledPromotionNameContainer>
            <BaseTypography
              fontType={FONT_TYPE.BODY}
              fontSize={FONT_SIZE.MD}
              isBold
            >
              {promotionSpot.promotionPublishment.promotion.name}
            </BaseTypography>
          </StyledPromotionNameContainer>
          <StyledPromotionDescriptionContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              {promotionSpot.promotionPublishment.promotion.description}
            </BaseTypography>
          </StyledPromotionDescriptionContainer>
        </Grid>
      </StyledPromoterCardSection>
    </CardActionArea>
  </StyledCard>
);

export { Presenter };
