import { combineReducers } from 'redux';
import { frequentlyAskedQuestions } from '../page/frequentlyAskedQuestions';
import { existSameConfirmedEmail } from '../page/signUpPage';

import { announcementDetail } from './announcementDetail';
import {
  announcementsConnection,
  announcementsConnectionActions,
} from './announcementsConnection';
import {
  availableMyGiftsConnection,
  availableMyGiftsConnectionActions,
} from './availableMyGiftsConnection';
import { brand, brandActions } from './brand';
import { changePageActions } from './changePage';
import { chargedPoint } from './chargedPoint';
import {
  createPointExchangeInput,
  createPointExchangeInputActions,
} from './createPointExchangeInput';
import { currentSession } from './currentSession';
import { currentUser } from './currentUser';
import {
  displayOnlyExchangeableBrands,
  displayOnlyExchangeableBrandsActions,
} from './displayOnlyExchangeableBrands';
import {
  exchangeableBrandsConnection,
  exchangeableBrandsConnectionActions,
} from './exchangeableBrandsConnection';
import { exchangedGifts } from './exchangedGifts';
import {
  expiringMyGiftsConnection,
  expiringMyGiftsConnectionActions,
} from './expiringMyGiftsConnection';
import { giftConvertToPointRequest } from './giftConvertToPointRequest';
import { itemDetail, itemDetailActions } from './itemDetail';
import {
  itemSearchCondition,
  itemSearchConditionActions,
} from './itemSearchCondition';
import { myGift } from './myGift';
import { nonRecommendedBrandsConnection } from './nonRecommendedBrandsConnection';
import { pageError } from './pageError';
import {
  pointChargeWithSerialCodeError,
  pointChargeWithSerialCodeErrorActions,
} from './pointChargeWithSerialCodeError';
import {
  pointConvertableMyGiftsConnection,
  pointConvertableMyGiftsConnectionActions,
} from './pointConvertableMyGiftsConnection';
import { pointFeatureGroups } from './pointFeatureGroups';
import { pointWallet } from './pointWallet';
import { promotionSpots } from './promotionSpots';
import { publicAssets } from './publicAssets';
import { recommendedBrandsConnection } from './recommendedBrandsConnection';
import {
  searchedItemsConnection,
  searchedItemsConnectionActions,
} from './searchedItemsConnection';
import { selectedGifteeContent } from './selectedGifteeContent';
import { selectedMyGifts } from './selectedMyGifts';
import {
  unavailableMyGiftsConnection,
  unavailableMyGiftsConnectionActions,
} from './unavailableMyGiftsConnection';
import { userAuthenticators } from './userAuthenticators';

export const appReducer = combineReducers({
  currentUser,
  currentSession,
  userAuthenticators,
  promotionSpots,
  selectedGifteeContent,
  expiringMyGiftsConnection,
  availableMyGiftsConnection,
  unavailableMyGiftsConnection,
  exchangeableBrandsConnection,
  nonRecommendedBrandsConnection,
  searchedItemsConnection,
  recommendedBrandsConnection,
  itemSearchCondition,
  pointWallet,
  pageError,
  selectedMyGifts,
  itemDetail,
  createPointExchangeInput,
  exchangedGifts,
  myGift,
  pointConvertableMyGiftsConnection,
  brand,
  pointFeatureGroups,
  displayOnlyExchangeableBrands,
  chargedPoint,
  pointChargeWithSerialCodeError,
  announcementsConnection,
  announcementDetail,
  publicAssets,
  giftConvertToPointRequest,
  frequentlyAskedQuestions,
  existSameConfirmedEmail,
});

export const appActions = {
  ...changePageActions,
  ...searchedItemsConnectionActions,
  ...itemSearchConditionActions,
  ...itemDetailActions,
  ...createPointExchangeInputActions,
  ...brandActions,
  ...displayOnlyExchangeableBrandsActions,
  ...pointChargeWithSerialCodeErrorActions,
  ...pointConvertableMyGiftsConnectionActions,
  ...availableMyGiftsConnectionActions,
  ...expiringMyGiftsConnectionActions,
  ...unavailableMyGiftsConnectionActions,
  ...exchangeableBrandsConnectionActions,
  ...announcementsConnectionActions,
};
