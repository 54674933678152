import React from 'react';
import { Presenter } from './Presenter';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { useAppSelector } from '/@/store';

const Container: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate(PATHS.HOME);
  };

  const errorMessage = useAppSelector(
    (state) => state.app.pointChargeWithSerialCodeError?.errorMessage,
  );

  if (errorMessage === undefined) {
    navigateToHome();
    return null;
  }

  return (
    <Presenter navigateToHome={navigateToHome} errorMessage={errorMessage} />
  );
};

export { Container as ErrorPage };
