import React from 'react';
import { DetailSections } from '/@/components/shared/detailSections/Index';

const Presenter: React.FC<Record<string, never>> = () => (
  <div data-testid="signInPageUsageSection">
    <DetailSections
      title="HOW TO USE"
      subTitle="使い方"
      details={[
        {
          number: 1,
          title: 'LINEでgift walletを友だち追加',
          description:
            '下のボタンからLINE認証に進み、「gift wallet」のLINEアカウントを友だち登録します。',
        },
        {
          number: 2,
          title: 'ギフトの通知を確認',
          description:
            '「gift wallet」のLINEアカウントから通知がきていることを確認します。正常に来ていれば登録完了です！',
        },
      ]}
    />
  </div>
);

export { Presenter };
