import React from 'react';
import { Modal, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledModal = styled(Modal)({
  backgroundColor: 'transparent',
  textAlign: 'center',
});
const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  width: 'calc(100% - 32px)',
  maxWidth: '480px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  alignItems: 'center',
}));

type Props = {
  children: React.ReactNode;
  handleClose: () => void;
};

const Presenter: React.FC<Props> = ({ children, handleClose }) => (
  <StyledModal open={true} onClose={handleClose}>
    <StyledBox>{children}</StyledBox>
  </StyledModal>
);

export { Presenter };
