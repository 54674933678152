import React from 'react';
import { Presenter } from './Presenter';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { GetAnnouncementsConnectionQuery } from '/@/api/graphql/internalApi/types';

export type AnnouncementItemType = NonNullable<
  NonNullable<
    GetAnnouncementsConnectionQuery['userAnnouncements']['nodes']
  >[number]
>;
type Props = {
  announcement: AnnouncementItemType;
};

const Container: React.FC<Props> = ({ announcement }) => {
  const navigate = useNavigate();
  const navigateAnnouncementDetail = () => {
    navigate(PATHS.ANNOUNCEMENT_DETAIL.replace(':uid', announcement.uid));
  };

  return (
    <Presenter
      announcement={announcement}
      navigateAnnouncementDetail={navigateAnnouncementDetail}
    />
  );
};

export { Container as AnnouncementItem };
