import React from 'react';
import { Presenter } from './Presenter';

const Container: React.FC<{
  unreadBadgeVisible: boolean;
  handleToggleMenu: () => void;
  handleClickGlobalMenu: () => void;
}> = ({ unreadBadgeVisible, handleToggleMenu, handleClickGlobalMenu }) => {
  return (
    <Presenter
      unreadBadgeVisible={unreadBadgeVisible}
      handleToggleMenu={handleToggleMenu}
      handleClickGlobalMenu={handleClickGlobalMenu}
    />
  );
};

export { Container as HeaderGrobalMenu };
