import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getSavingGifteeContentThunk } from '/@/store/api/queries/getSavingGifteeContent';

type Props = {
  url: string | null;
  uid: string | null;
  origin: OriginEnum;
  convertToPoint?: boolean | null;
  destination?: DestinationEnum | null;
  serialCode?: string | null;
};

const Container: React.FC<Props> = ({
  url,
  uid,
  origin,
  convertToPoint,
  destination,
  serialCode,
}) => {
  const dispatch = useAppDispatch();

  const getSavingGifteeContentStatus = useAppSelector(
    (state) => state.api.getSavingGifteeContent.status,
  );

  const savingGifteeContent = useAppSelector(
    (state) => state.page.signInPage.savingGifteeContent,
  );

  useEffect(() => {
    dispatch(
      getSavingGifteeContentThunk({
        url,
        uid,
        origin,
      }),
    ).catch(() => {});
  }, [dispatch, url, uid, origin]);

  return (
    <Presenter
      getSavingGifteeContentStatus={getSavingGifteeContentStatus}
      savingGifteeContent={savingGifteeContent}
      convertToPoint={convertToPoint}
      destination={destination}
      serialCode={serialCode}
    />
  );
};

export { Container as SavingGift };
