import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '/@/store';
import { Presenter } from './Presenter';
import { useAppDispatch } from '/@/store/hooks';
import { STATUS } from '/@/store/api/constants';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { getAnnouncementsConnectionThunk } from '/@/store/api/queries/getAnnouncementsConnection';
import { UserAnnouncementTypeCategoryUidEnumType } from '/@/api/graphql/internalApi/types';
import { initialiseAnnouncementsConnection } from '/@/store/app/announcementsConnection/actions';

type Props = {
  userAnnouncementTypeCategory: [UserAnnouncementTypeCategoryUidEnumType];
};

const Container: React.FC<Props> = ({ userAnnouncementTypeCategory }) => {
  const dispatch = useAppDispatch();
  const announcements = useAppSelector(
    (state) => state.app.announcementsConnection.announcements,
  );
  const announcementsConnectionStatus = useAppSelector(
    (state) => state.app.announcementsConnection.status,
  );
  const pageInfo = useAppSelector(
    (state) => state.app.announcementsConnection.pageInfo,
  );
  const [prevUserAnnouncementTypeCategory, setUserAnnouncementTypeCategory] =
    useState<[UserAnnouncementTypeCategoryUidEnumType] | []>([]);

  useEffect(() => {
    const isChangedCategory: boolean =
      prevUserAnnouncementTypeCategory.toString() !==
      userAnnouncementTypeCategory.toString();

    if (pageInfo === null || isChangedCategory) {
      // カテゴリが変更された場合はstoreを初期化してから取得
      if (isChangedCategory) {
        dispatch(initialiseAnnouncementsConnection());
      }

      dispatch(
        getAnnouncementsConnectionThunk({
          after: null,
          categoryUidIn: userAnnouncementTypeCategory,
        }),
      );
      setUserAnnouncementTypeCategory(userAnnouncementTypeCategory);
    }
  }, [
    dispatch,
    userAnnouncementTypeCategory,
    pageInfo,
    prevUserAnnouncementTypeCategory,
  ]);

  // 無限スクロール対応
  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      announcementsConnectionStatus !== STATUS.LOADING &&
      pageInfo?.hasNextPage
    ) {
      dispatch(
        getAnnouncementsConnectionThunk({
          after: pageInfo.endCursor ?? undefined,
          categoryUidIn: userAnnouncementTypeCategory,
        }),
      ).catch(() => {});
    }
  }, [
    dispatch,
    isIntersect,
    announcementsConnectionStatus,
    pageInfo,
    userAnnouncementTypeCategory,
  ]);

  return (
    <Presenter
      infiniteScrollTarget={infiniteScrollTarget}
      announcements={announcements}
      announcementsConnectionStatus={announcementsConnectionStatus}
    />
  );
};

export { Container as AnnouncementsList };
