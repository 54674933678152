import React, { useState } from 'react';
import { Presenter } from './Presenter';
import { readAnnouncementsThunk } from '/@/store/api/mutations/readAnnouncements';
import { useAppDispatch, useAppSelector } from '/@/store';
import { initialiseAnnouncementsConnection } from '/@/store/app/announcementsConnection/actions';
import { UserAnnouncementTypeCategoryUidEnumType } from '/@/api/graphql/internalApi/types';

type Props = {
  headerTitle: string;
  isDisabledReadButton: boolean;
  noAnnouncementText: string;
  userAnnouncementTypeCategory: UserAnnouncementTypeCategoryUidEnumType;
};

const Container: React.FC<Props> = ({
  headerTitle,
  isDisabledReadButton,
  noAnnouncementText,
  userAnnouncementTypeCategory,
}) => {
  const dispatch = useAppDispatch();
  const readAnnouncementsStatus = useAppSelector(
    (state) => state.api.readAnnouncements.status,
  );
  const announcementsConnection = useAppSelector(
    (state) => state.app.announcementsConnection,
  );
  // MEMO: 一括既読ボタンが無効の際のモーダル表示管理
  const [isDisplayModal, setIsDisplayModal] = useState<boolean>(false);

  const handleReadAllAnnouncements = () => {
    dispatch(
      readAnnouncementsThunk({
        categoryUid: userAnnouncementTypeCategory,
      }),
    ).then(() => {
      dispatch(initialiseAnnouncementsConnection());
    });
  };

  return (
    <Presenter
      headerTitle={headerTitle}
      handleReadAllAnnouncements={handleReadAllAnnouncements}
      readAnnouncementsStatus={readAnnouncementsStatus}
      isDisabledReadButton={isDisabledReadButton}
      isNoAnnouncement={announcementsConnection?.announcements?.length === 0}
      noAnnouncementText={noAnnouncementText}
      userAnnouncementTypeCategory={userAnnouncementTypeCategory}
      isDisplayModal={isDisplayModal}
      setIsDisplayModal={setIsDisplayModal}
    />
  );
};

export { Container as AnnouncementBasePage };
