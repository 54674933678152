import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '40px',
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.spacing(3),
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
      color: theme.palette.primary.light,
    },
}));

const StyledTabButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.secondary.dark + ' !important',
  fontSize: '0.75rem',
  lineHeight: '1.5',
  fontWeight: 'bold',
  flex: 1,
  '&.Mui-selected': {
    color: theme.palette.common.white + ' !important',
    backgroundColor: theme.palette.common.black,
    borderRadius: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

type Props = {
  selectedTab: string;
  tabs: {
    value: string;
    label: string;
    dataTestid: string;
  }[];
  handleChangeTab: (event: string) => void;
};
const Presenter: React.FC<Props> = ({ selectedTab, tabs, handleChangeTab }) => {
  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={selectedTab}
          exclusive
          onChange={(e, value) =>
            value !== null && handleChangeTab(value as string)
          }
        >
          {tabs.map((tab) => (
            <StyledTabButton
              key={tab.value}
              value={tab.value}
              aria-label={tab.label}
              data-testid={tab.dataTestid}
            >
              {tab.label}
            </StyledTabButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
};

export { Presenter };
