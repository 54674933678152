import { gql } from '@apollo/client';

export const verifyEmailGql = gql`
  mutation VerifyEmail($verifyEmailInput: VerifyEmailInput!) {
    verifyEmail(input: $verifyEmailInput) {
      registrationSession {
        giftSavingOption {
          url
          origin
          referer
        }
        destinationOption {
          name
        }
        pointSerialOption {
          serialCode
        }
      }
      loginSession {
        token
        expiredAt
      }
      errors {
        message
        path
        extensions {
          status
          code
        }
      }
    }
  }
`;
