import React from 'react';
import { CircularProgress, styled } from '@mui/material';

const StyledCircularProgress = styled(CircularProgress)({
  color: '#1DABD6',
});

const Presenter: React.FC<Record<string, never>> = () => (
  <StyledCircularProgress size={16} color="primary" />
);

export { Presenter };
