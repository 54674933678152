import React from 'react';
import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExchangedGifts } from './exchangedGifts/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { ItemDetailModal } from '/@/components/shared/pointItemContents/itemDetailModal/Index';
import { PointLogType_StatusEnumType } from '/@/api/graphql/internalApi/types';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';

const StyledExchangedGiftsBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  paddingTop: theme.spacing(3),
}));
const StyledExchangedGiftsTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.secondary.dark,
}));
const StyledExchangedGifts = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  minWidth: '100%',
  maxWidth: '100%',
}));
const StyledAlertMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.primary.dark,
  padding: theme.spacing(3),
}));

type Props = {
  pointLogStatus?: PointLogType_StatusEnumType;
};

const Presenter: React.FC<Props> = ({ pointLogStatus }) => (
  <MainLayout>
    <StyledExchangedGiftsBackground>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <StyledExchangedGiftsTitle>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.MD}
            isBold
          >
            {pointLogStatus === PointLogType_StatusEnumType.Retrying
              ? 'ギフト発行中に問題が発生しました'
              : 'ギフトを交換しました'}
          </BaseTypography>
        </StyledExchangedGiftsTitle>
        <StyledExchangedGifts>
          {pointLogStatus === PointLogType_StatusEnumType.Retrying ? (
            <StyledAlertMessage>
              ギフト発行中に問題が発生しました。
              <br />
              少し時間をおいてから、マイギフトページより再度実行してください。
            </StyledAlertMessage>
          ) : (
            <ExchangedGifts />
          )}
        </StyledExchangedGifts>
      </Grid>
    </StyledExchangedGiftsBackground>
    <ItemDetailModal />
  </MainLayout>
);

export { Presenter };
