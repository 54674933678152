import React from 'react';
import { Presenter } from './Presenter';

const Container: React.FC<{
  isLogoClickable: boolean;
}> = ({ isLogoClickable }) => {
  return <Presenter isLogoClickable={isLogoClickable} />;
};

export { Container as HeaderLogo };
