import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DestinationEnum } from '/@/api/graphql/publicApi/types';
import { Modal } from '/@/components/shared/modal/Index';
import { LoginButton } from '/@/components/shared/loginButton/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';

const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  color: theme.palette.secondary.dark,
}));

const StyledCloseLinkContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type Props = {
  modalOpen: boolean;
  handleClose: () => void;
};

const Presenter: React.FC<Props> = ({ modalOpen, handleClose }) => (
  <>
    {modalOpen && (
      <Modal handleClose={handleClose} data-testid="modal-inquiryLogin">
        <Grid container direction="column">
          <StyledTitleContainer>
            <BaseTypography
              fontType={FONT_TYPE.HEADLINE}
              fontSize={FONT_SIZE.MD}
              isBold
            >
              【お問い合わせに関して】
            </BaseTypography>
          </StyledTitleContainer>
          <StyledDescriptionContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              LINEログインすることで
              <br />
              お客様の状況確認がスムーズに行え、
              <br />
              より早く適切なご案内ができる場合があります。
            </BaseTypography>
          </StyledDescriptionContainer>
          <LoginButton
            url={null}
            origin={null}
            referer={null}
            convertToPoint={null}
            destination={DestinationEnum.Inquiry}
            serialCode={null}
            data-gtm-click="inquiriesPage-newPage-loginModal-loginButton"
          >
            ログインする
          </LoginButton>
          <StyledCloseLinkContainer>
            <BaseButton
              isTransparent
              onClick={handleClose}
              data-gtm-click="inquiriesPage-newPage-loginModal-closeButton"
            >
              このまま問い合わせる
            </BaseButton>
          </StyledCloseLinkContainer>
        </Grid>
      </Modal>
    )}
  </>
);

export { Presenter };
