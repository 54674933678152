import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Presenter } from './Presenter';
import { SignUpUserInput } from './types';
import { AUTHENTICATION_STATUS, useAuth } from '/@/common/hooks/useAuth';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { PATHS } from '/@/routes/paths';
import { useAppDispatch, useAppSelector } from '/@/store';
import { createUserRegistrationSessionThunk } from '/@/store/api/mutations/createUserRegistrationSession';
import {
  selectEmail,
  selectIsSignUpLoading,
} from '/@/store/page/signUpPage/selectors';
import { createOauthSessionOption } from '/@/utils/createOauthSessionOption';
import { decideGoForwardLocation } from '/@/utils/decideLocationAfterOauth';
import { genderCodes } from '/@/utils/genderCodeMapping';
import { prefectureCodes } from '/@/utils/prefectureCodeMapping';

const Container: React.FC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const errorMessage = location.state?.errorMessage;
  const { search } = location;
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const serialCode = urlSearchParams.get('serial_code');
  const serialCodeEndAt = urlSearchParams.get('end_at');
  const authenticationStatus = useAuth();

  const navigate = useNavigate();
  const { pushClickEvent } = useDataLayer();
  const email = useAppSelector(selectEmail);

  // 同一画面内かつ、フォーム横断の状態保存用途

  const [signUpUserInput, setSignUpUserInput] = useState<SignUpUserInput>({
    password: '',
    isTermsAgreed: false,
    genderCode: '',
    birthdayYear: '',
    birthdayMonth: '',
    birthdayDay: '',
    prefectureCode: '',
  });
  const isLoading = useAppSelector(selectIsSignUpLoading);

  const {
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { email, password: signUpUserInput?.password },
    mode: 'onBlur',
  });
  // FIXME: 引き継ぎ予定でCIを通すためコメントアウト
  // const [isSerialCodeOutOfPeriod, setIsSerialCodeOutOfPeriod] =
  //   useState<boolean>(false);

  useEffect(() => {
    if (
      authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED &&
      !errorMessage &&
      !serialCode
    ) {
      const { giftSavingOption, destinationOption } =
        createOauthSessionOption(urlSearchParams);

      navigate(
        decideGoForwardLocation({
          giftSavingOption,
          destinationOption,
        }),
        { replace: true },
      );
    }
  }, [
    authenticationStatus,
    errorMessage,
    serialCode,
    urlSearchParams,
    navigate,
  ]);

  const { pushSignUpEvent } = useDataLayer();

  const pushDatalayer = () => {
    pushSignUpEvent({
      gender: genderCodes.find(
        (g) => g.code === Number(signUpUserInput?.genderCode),
      )?.name,
      birthday: `${signUpUserInput?.birthdayYear}/${signUpUserInput?.birthdayMonth}/${signUpUserInput?.birthdayDay}`,
      birthYear: signUpUserInput?.birthdayYear ?? undefined,
      prefecture: prefectureCodes.find(
        (p) => p.code === Number(signUpUserInput?.prefectureCode),
      )?.name,
      customData: {
        gender: genderCodes.find(
          (g) => g.code === Number(signUpUserInput?.genderCode),
        )?.name,
        birthday: `${signUpUserInput?.birthdayYear}/${signUpUserInput?.birthdayMonth}/${signUpUserInput?.birthdayDay}`,
        birthYear: signUpUserInput?.birthdayYear ?? undefined,
        prefecture: prefectureCodes.find(
          (p) => p.code === Number(signUpUserInput?.prefectureCode),
        )?.name,
      },
    });
  };

  useEffect(() => {
    if (serialCodeEndAt) {
      // クエリパラメータ取得時に+がスペースに変換されてしまうため、元に戻す
      const endAt = new Date(serialCodeEndAt.replace(' ', '+')).getTime();
      const now = new Date().getTime();

      if (endAt < now) {
        // FIXME: 引き継ぎ予定でCIを通すためコメントアウト
        // setIsSerialCodeOutOfPeriod(true);
      }
    }
  }, [dispatch, serialCodeEndAt]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    pushClickEvent({
      clickItem: 'signUpSubmitButton',
      customData: {
        clickItem: 'signUpSubmitButton',
      },
    });

    if (signUpUserInput) {
      dispatch(
        createUserRegistrationSessionThunk({
          email,
          password: signUpUserInput.password,
          genderCode: signUpUserInput.genderCode ?? '',
          prefectureCode: String(signUpUserInput?.prefectureCode ?? ''),
          year: signUpUserInput.birthdayYear,
          month: signUpUserInput.birthdayMonth,
          day: signUpUserInput.birthdayDay,
        }),
      )
        .then(() => {
          navigate(PATHS.SIGN_UP_COMPLETION);
        })
        .catch(() => {});
    }
  };

  return (
    <Presenter
      control={control}
      errors={errors}
      isValid={isValid}
      trigger={trigger}
      isLoading={isLoading}
      signUpUserInput={signUpUserInput}
      setSignUpUserInput={setSignUpUserInput}
      pushDatalayer={pushDatalayer}
      serialCode={serialCode}
      handleSubmit={handleSubmit}
    />
  );
};

export { Container as SignUpPage };
