import React from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { Presenter } from './Presenter';
import {
  giftsAvailabilityTabActions,
  SelectedGiftsAvailabilityTabState,
} from '/@/store/ui/selectedGiftsAvailabilityTab';

const Container: React.FC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const selectedGiftsAvailabilityTab = useAppSelector(
    (state) => state.ui.selectedGiftsAvailabilityTab,
  );

  const handleGiftsAvailabilityTab = (
    selectedGiftsAvailabilityTabState: SelectedGiftsAvailabilityTabState,
  ) => {
    dispatch(
      giftsAvailabilityTabActions.selectgiftsAvailabilityTab(
        selectedGiftsAvailabilityTabState,
      ),
    );
  };

  return (
    <Presenter
      handleGiftsAvailabilityTab={handleGiftsAvailabilityTab}
      selectedGiftsAvailabilityTab={selectedGiftsAvailabilityTab}
    />
  );
};

export { Container as GiftsAvailabilityTabs };
