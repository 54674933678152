import React from 'react';
import { Divider, styled } from '@mui/material';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.secondary.light,
  borderWidth: '1px',
}));

const Presenter: React.FC<Record<string, never>> = () => {
  return <StyledDivider />;
};

export { Presenter };
