import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BaseButton } from '../button/baseButton/Index';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../typography/Index';
import lineLoginButton from '/@/assets/shared/button/line-button.svg';
import { PATHS } from '/@/routes/paths';
import { theme } from '/@/utils/theme';

const StyledContainer = styled('div')({
  textAlign: 'center',
});
const StyledNoteContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const StyledConvertToPointNoteContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.grey[700],
  width: '100%',
  textAlign: 'center',
}));
const StyledLoginButtonContainer = styled('div')({
  fontWeight: 'bold',
  width: '100%',
});

const StyledLineLoginButton = styled('img')({
  cursor: 'pointer',
});

type Props = {
  children: string;
  onClick: () => Promise<void> | undefined;
  isDisplayNoteForPointConvert: boolean;
  isDisplayButtonOnly: boolean;
  isDisplayLineImage: boolean;
};

const Presenter: React.FC<Props> = ({
  children,
  onClick,
  isDisplayNoteForPointConvert,
  isDisplayButtonOnly,
  isDisplayLineImage,
}) => (
  <StyledContainer>
    {isDisplayButtonOnly === false && (
      <StyledNoteContainer>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
          <Link
            component={RouterLink}
            to={PATHS.TERMS_OF_SERVICE}
            style={{
              color: theme.palette.secondary.dark,
              textDecorationColor: theme.palette.secondary.dark,
            }}
          >
            サービス利用規約
          </Link>
          に同意したことになります
        </BaseTypography>
        {isDisplayNoteForPointConvert && (
          <StyledConvertToPointNoteContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              ※ポイントは残高全てが移行されます。ご注意ください。
            </BaseTypography>
          </StyledConvertToPointNoteContainer>
        )}
      </StyledNoteContainer>
    )}
    <StyledLoginButtonContainer>
      {isDisplayLineImage ? (
        <StyledLineLoginButton
          height={48}
          onClick={onClick}
          data-gtm-click="shared-loginButton-loginButtonLineImage"
          src={lineLoginButton}
          alt="lineLoginButton"
        />
      ) : (
        <BaseButton
          onClick={onClick}
          data-gtm-click="shared-loginButton-loginButton"
        >
          {children}
        </BaseButton>
      )}
    </StyledLoginButtonContainer>
  </StyledContainer>
);

export { Presenter };
