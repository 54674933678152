import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { SavingGift } from './savingGift/Index';
import {
  getLoginButtonText,
  hasValidGiftParameters,
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import { ErrorMessage } from '/@/store/api/mutations/saveGifteeContent/selectors';
import { formatedISO8601Date } from '/@/utils/formatDate';

const StyledLoginSectionContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));
const StyledAuthenticationErrorMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
  padding: theme.spacing(3),
}));
const StyledSerialCodeOutOfPeriodMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
}));
const StyledSerialCodePeriodMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

type Props = LoginOption & {
  uid: string | null;
  authenticationErrorMessage: ErrorMessage;
  isSerialCodeOutOfPeriod: boolean;
  serialCodeEndAt: string | null;
};

const Presenter: React.FC<Props> = ({
  url,
  uid,
  origin,
  convertToPoint,
  destination,
  serialCode,
  authenticationErrorMessage,
  isSerialCodeOutOfPeriod,
  serialCodeEndAt,
}) => {
  const loginButtonText = getLoginButtonText(serialCode, url, uid, origin);

  return (
    <div data-testid="signInPageLoginSection">
      {authenticationErrorMessage && (
        <StyledAuthenticationErrorMessage variant="body2">
          {authenticationErrorMessage}
        </StyledAuthenticationErrorMessage>
      )}
      {isSerialCodeOutOfPeriod && (
        <StyledSerialCodeOutOfPeriodMessage variant="body2">
          {`このURLは有効期限切れです`}
        </StyledSerialCodeOutOfPeriodMessage>
      )}
      {serialCodeEndAt && !isSerialCodeOutOfPeriod && (
        <StyledSerialCodePeriodMessage variant="body2">
          {`チャージの有効期限は${formatedISO8601Date(serialCodeEndAt.replace(' ', '+'))}です`}
        </StyledSerialCodePeriodMessage>
      )}
      {origin && hasValidGiftParameters(url, uid, origin) && (
        <div>
          <SavingGift
            url={url}
            uid={uid}
            origin={origin}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={serialCode}
          />
        </div>
      )}
      {/* MEMO: ギフト保存時は非表示 */}
      {!hasValidGiftParameters(url, uid, origin) && (
        <StyledLoginSectionContainer>
          <LoginButton
            url={null}
            origin={null}
            referer={null}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={serialCode}
          >
            {loginButtonText}
          </LoginButton>
        </StyledLoginSectionContainer>
      )}
    </div>
  );
};

export { Presenter };
