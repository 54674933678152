import { combineReducers } from 'redux';
import { handleErrorActions } from './handleError';
import {
  createGiftConvertToPoint,
  createGiftConvertToPointActions,
} from './mutations/createGiftConvertToPoint';
import {
  createOauthSession,
  createOauthSessionActions,
} from './mutations/createOauthSession';
import {
  createPointChargeWithSerialCode,
  createPointChargeWithSerialCodeActions,
} from './mutations/createPointChargeWithSerialCode';
import {
  createPointExchange,
  createPointExchangeActions,
} from './mutations/createPointExchange';
import {
  createUserProfile,
  createUserProfileActions,
} from './mutations/createUserProfile';
import {
  createUserRegistrationSession,
  createUserRegistrationSessionActions,
} from './mutations/createUserRegistrationSession';
import {
  discardMyGifts,
  discardMyGiftsActions,
} from './mutations/discardMyGifts';
import { notifyInquiry, notifyInquiryActions } from './mutations/notifyInquiry';
import {
  readAnnouncement,
  readAnnouncementActions,
} from './mutations/readAnnouncement';
import {
  readAnnouncements,
  readAnnouncementsActions,
} from './mutations/readAnnouncements';
import { refreshMyGift, refreshMyGiftActions } from './mutations/refreshMyGift';
import {
  saveGifteeContent,
  saveGifteeContentActions,
} from './mutations/saveGifteeContent';
import {
  signInOrSignUp,
  signInOrSignUpActions,
} from './mutations/signInOrSignUp';
import { verifyEmail, verifyEmailActions } from './mutations/verifyEmail';
import {
  getAnnouncement,
  getAnnouncementActions,
} from './queries/getAnnouncement';
import {
  getAnnouncementsConnection,
  getAnnouncementsConnectionActions,
} from './queries/getAnnouncementsConnection';
import {
  getAvailablePointCharges,
  getAvailablePointChargesActions,
} from './queries/getAvailablePointCharges';
import { getBrand, getBrandActions } from './queries/getBrand';
import {
  getBrandsConnection,
  getBrandsConnectionActions,
} from './queries/getBrandsConnection';
import {
  getCurrentUser,
  getCurrentUserActions,
} from './queries/getCurrentUser';
import {
  getExistSameConfirmedEmail,
  getExistSameConfirmedEmailActions,
} from './queries/getExistSameConfirmedEmail';
import {
  getFrequentlyAskedQuestions,
  getFrequentlyAskedQuestionsActions,
} from './queries/getFrequentlyAskedQuestions';
import {
  getGiftConvertToPointRequest,
  getGiftConvertToPointRequestActions,
} from './queries/getGiftConvertToPointRequest';
import {
  getGifteeContent,
  getGifteeContentActions,
} from './queries/getGifteeContent';
import { getItemDetail, getItemDetailActions } from './queries/getItemDetail';
import {
  getItemsConnection,
  getItemsConnectionActions,
} from './queries/getItemsConnection';
import { getMyGift, getMyGiftActions } from './queries/getMyGift';
import {
  getMyGiftsConnection,
  getMyGiftsConnectionActions,
} from './queries/getMyGiftsConnection';
import {
  getOauthSession,
  getOauthSessionActions,
} from './queries/getOauthSession';
import {
  getPointFeatureGroups,
  getPointFeatureGroupsActions,
} from './queries/getPointFeatureGroups';
import { getPointLogs, getPointLogsActions } from './queries/getPointLogs';
import {
  getPointWallet,
  getPointWalletActions,
} from './queries/getPointWallet';
import {
  getPromotionSpots,
  getPromotionSpotsActions,
} from './queries/getPromotionSpots';
import {
  getPublicAssets,
  getPublicAssetsActions,
} from './queries/getPublicAssets';
import {
  getSavingGifteeContent,
  getSavingGifteeContentActions,
} from './queries/getSavingGifteeContent';
import {
  getUserAuthenticators,
  getUserAuthenticatorsActions,
} from './queries/getUserAuthenticators';
import {
  giftConvertToPointRequestSubscription,
  giftConvertToPointRequestSubscriptionActions,
} from './subscriptions/giftConvertToPointRequestSubscription';
import {
  pointUpdatedSubscription,
  pointUpdatedSubscriptionActions,
} from './subscriptions/pointUpdatedSubscription';
export const apiReducer = combineReducers({
  getPromotionSpots,
  getUserAuthenticators,
  saveGifteeContent,
  getGifteeContent,
  getPointWallet,
  getAvailablePointCharges,
  getPointLogs,
  notifyInquiry,
  createOauthSession,
  createUserRegistrationSession,
  verifyEmail,
  signInOrSignUp,
  getOauthSession,
  getSavingGifteeContent,
  createUserProfile,
  getCurrentUser,
  discardMyGifts,
  getBrandsConnection,
  getItemsConnection,
  getItemDetail,
  createPointExchange,
  pointUpdatedSubscription,
  getMyGift,
  getMyGiftsConnection,
  getPointFeatureGroups,
  createGiftConvertToPoint,
  giftConvertToPointRequestSubscription,
  getBrand,
  refreshMyGift,
  createPointChargeWithSerialCode,
  getAnnouncementsConnection,
  getAnnouncement,
  readAnnouncements,
  readAnnouncement,
  getPublicAssets,
  getGiftConvertToPointRequest,
  getFrequentlyAskedQuestions,
  getExistSameConfirmedEmail,
});

export const apiActions = {
  ...getPromotionSpotsActions,
  ...getUserAuthenticatorsActions,
  ...saveGifteeContentActions,
  ...getGifteeContentActions,
  ...getPointWalletActions,
  ...getAvailablePointChargesActions,
  ...getPointLogsActions,
  ...notifyInquiryActions,
  ...createOauthSessionActions,
  ...createUserRegistrationSessionActions,
  ...verifyEmailActions,
  ...signInOrSignUpActions,
  ...getOauthSessionActions,
  ...getSavingGifteeContentActions,
  ...createUserProfileActions,
  ...handleErrorActions,
  ...getCurrentUserActions,
  ...discardMyGiftsActions,
  ...getBrandsConnectionActions,
  ...getItemsConnectionActions,
  ...getItemDetailActions,
  ...createPointExchangeActions,
  ...pointUpdatedSubscriptionActions,
  ...getMyGiftActions,
  ...getMyGiftsConnectionActions,
  ...createGiftConvertToPointActions,
  ...getPointFeatureGroupsActions,
  ...giftConvertToPointRequestSubscriptionActions,
  ...getBrandActions,
  ...refreshMyGiftActions,
  ...createPointChargeWithSerialCodeActions,
  ...getAnnouncementsConnectionActions,
  ...getAnnouncementActions,
  ...readAnnouncementsActions,
  ...readAnnouncementActions,
  ...getPublicAssetsActions,
  ...getGiftConvertToPointRequestActions,
  ...getFrequentlyAskedQuestionsActions,
  ...getExistSameConfirmedEmailActions,
};
