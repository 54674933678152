import React, { ChangeEvent, useCallback, useState } from 'react';
import { Control, FieldErrors, UseFormTrigger } from 'react-hook-form';
import { SignUpUserInput } from '../types';
import { Presenter } from './Presenter';
import { useAppDispatch } from '/@/store';
import { getExistSameConfirmedEmailThunk } from '/@/store/api/queries/getExistSameConfirmedEmail';
import { updateSignUpEmail } from '/@/store/page/signUpPage/actions';

type BasicInfoFormData = {
  email: string;
  password: string;
};

type Props = {
  control: Control<BasicInfoFormData>;
  errors: FieldErrors<BasicInfoFormData>;
  isValid: boolean;
  trigger: UseFormTrigger<BasicInfoFormData>;
  signUpUserInput: SignUpUserInput;
  setSignUpUserInput: React.Dispatch<React.SetStateAction<SignUpUserInput>>;
  handleClickNextButton: () => void;
};

const Container: React.FC<Props> = ({
  control,
  errors,
  isValid,
  trigger,
  signUpUserInput,
  setSignUpUserInput,
  handleClickNextButton,
}) => {
  const dispatch = useAppDispatch();
  const [emailExistErrorMessage, setEmailExistErrorMessage] = useState('');

  const handleChangeEmail = (email: string) => {
    dispatch(updateSignUpEmail(email));
  };
  const handleChangePassword = (password: string) => {
    setSignUpUserInput((prevState) => ({ ...prevState, password }));
  };
  const handleChangeIsTermsAgreed = (event: ChangeEvent<HTMLInputElement>) => {
    setSignUpUserInput((prevState) => ({
      ...prevState,
      isTermsAgreed: event.target.checked,
    }));
  };

  const validateEmailAndExistCheck = useCallback(
    async (email: string) => {
      const exists = await dispatch(getExistSameConfirmedEmailThunk({ email }));
      if (exists) {
        setEmailExistErrorMessage('このメールアドレスは登録済みです');
      } else {
        setEmailExistErrorMessage('');
      }
    },
    [dispatch],
  );

  return (
    <Presenter
      handleChangeEmail={handleChangeEmail}
      handleChangePassword={handleChangePassword}
      handleChangeIsTermsAgreed={handleChangeIsTermsAgreed}
      validateEmailAndExistCheck={validateEmailAndExistCheck}
      emailExistErrorMessage={emailExistErrorMessage}
      control={control}
      errors={errors}
      isValid={isValid}
      trigger={trigger}
      signUpUserInput={signUpUserInput}
      handleClickNextButton={handleClickNextButton}
    />
  );
};

export { Container as BasicInfo };
