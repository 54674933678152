import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  styled,
  TextField,
} from '@mui/material';
import React, { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormTrigger,
} from 'react-hook-form';
import { SignUpUserInput } from '../types';
import checkboxCheckedIcon from '/@/assets/shared/icon/checkbox-checked-icon.svg';
import checkboxNotcheckedIcon from '/@/assets/shared/icon/checkbox-notchecked-icon.svg';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { theme } from '/@/utils/theme';
import { validateEmail, validatePassword } from '/@/utils/validate';

type BasicInfoFormData = {
  email: string;
  password: string;
};

type Props = {
  control: Control<BasicInfoFormData>;
  errors: FieldErrors<BasicInfoFormData>;
  isValid: boolean;
  trigger: UseFormTrigger<BasicInfoFormData>;
  handleChangeEmail: (value: string) => void;
  handleChangePassword: (value: string) => void;
  handleChangeIsTermsAgreed: (event: ChangeEvent<HTMLInputElement>) => void;
  validateEmailAndExistCheck: (email: string) => Promise<void>;
  emailExistErrorMessage: string;
  signUpUserInput: SignUpUserInput;
  handleClickNextButton: () => void;
};
const StyledSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  textAlign: 'center',
}));
const StyledSubTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginBottom: theme.spacing(3),
  textAlign: 'center',
}));

const StyledCautionContainer = styled('div')({
  color: theme.palette.secondary.main,
});
const StyledCautionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.hint,
  borderRadius: theme.spacing(),
}));
const StyledGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-asterisk': {
    display: 'none',
  },
});
const StyledFormLabelContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(),
}));
const StyledFormLabel = styled('div')({
  color: theme.palette.secondary.dark,
});
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `2px solid ${theme.palette.secondary.light}`,
      borderRadius: theme.spacing(1),
    },
    '&:hover fieldset': {
      border: `2px solid ${theme.palette.secondary.dark}`,
      borderColor: `${theme.palette.secondary.dark}`,
    },
  },
}));
const StyledCheckBoxLabel = styled('div')({
  color: theme.palette.secondary.dark,
});
const StyledAtag = styled('a')({
  color: theme.palette.secondary.dark,
});

const Presenter: React.FC<Props> = ({
  handleChangeEmail,
  handleChangePassword,
  handleChangeIsTermsAgreed,
  validateEmailAndExistCheck,
  emailExistErrorMessage,
  control,
  errors,
  isValid,
  trigger,
  signUpUserInput,
  handleClickNextButton,
}) => (
  <StyledSection maxWidth="sm">
    <StyledTitleContainer>
      <BaseTypography
        fontType={FONT_TYPE.HEADLINE}
        fontSize={FONT_SIZE.LG}
        isBold
      >
        新規登録
      </BaseTypography>
    </StyledTitleContainer>
    <StyledSubTitleContainer>
      <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
        サービスを利用するにはgift wallet Accountの
      </BaseTypography>
      <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
        登録が必要です
      </BaseTypography>
    </StyledSubTitleContainer>
    <Grid container direction="column" alignItems="stretch" spacing={2}>
      <StyledGridItem>
        <FormControl
          fullWidth
          error={!!errors.email || !!emailExistErrorMessage}
        >
          <StyledFormLabelContainer
            container
            direction="row"
            alignItems="center"
          >
            <StyledFormLabel>メールアドレス</StyledFormLabel>
          </StyledFormLabelContainer>
          <Controller
            name="email"
            control={control}
            rules={{ validate: validateEmail }}
            render={({ field }) => (
              <StyledTextField
                {...field}
                id="sign-up-email-address"
                required
                variant="outlined"
                autoComplete="email"
                onChange={(event) => {
                  field.onChange(event);
                  handleChangeEmail(event.target.value);
                }}
                onBlur={(e) => {
                  field.onBlur();
                  trigger('email');
                  validateEmailAndExistCheck(e.target.value);
                }}
                inputProps={{
                  'data-testid': 'sign-up-email-input',
                  type: 'email',
                }}
                slotProps={{
                  inputLabel: { shrink: true },
                }}
              />
            )}
          />
          {(errors.email || emailExistErrorMessage) && (
            <FormHelperText>
              {errors?.email?.message || emailExistErrorMessage}
            </FormHelperText>
          )}
        </FormControl>
      </StyledGridItem>
      <StyledGridItem>
        <FormControl fullWidth>
          <StyledFormLabelContainer
            container
            direction="row"
            alignItems="center"
          >
            <StyledFormLabel>パスワード</StyledFormLabel>
          </StyledFormLabelContainer>
          <FormControl fullWidth error={!!errors.password}>
            <Controller
              name="password"
              control={control}
              rules={{ validate: validatePassword }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  id="sign-up-password"
                  required
                  variant="outlined"
                  autoComplete="password"
                  type="password"
                  onChange={(event) => {
                    field.onChange(event);
                    handleChangePassword(event.target.value);
                  }}
                  onBlur={() => {
                    field.onBlur();
                    trigger('password');
                  }}
                  inputProps={{
                    'data-testid': 'sign-up-password-input',
                    type: 'password',
                  }}
                  slotProps={{
                    inputLabel: { shrink: true },
                  }}
                />
              )}
            />
            {errors.password && (
              <FormHelperText>{errors.password?.message}</FormHelperText>
            )}
          </FormControl>
          <StyledCautionContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
              英小文字・大文字・数字を組み合わせて半角10文字以上で入力してください
            </BaseTypography>
          </StyledCautionContainer>
        </FormControl>
      </StyledGridItem>
      <StyledGridItem>
        <StyledCautionBox>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
            <StyledAtag
              href="/terms_of_service/"
              target="_blank"
              rel="noopener noreferrer"
            >
              「利用規約」
            </StyledAtag>
            及び
            <StyledAtag
              href="https://giftee.com/privacy_policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              「個人情報取扱について」
            </StyledAtag>
            同意の上でお進みください
          </BaseTypography>
          <StyledFormControlLabel
            label={
              <StyledCheckBoxLabel>
                <BaseTypography
                  fontType={FONT_TYPE.BODY}
                  fontSize={FONT_SIZE.MD}
                >
                  同意する
                </BaseTypography>
              </StyledCheckBoxLabel>
            }
            control={
              <Checkbox
                name="agreeWithTheTreatmentOfPersonalData"
                required
                checked={signUpUserInput.isTermsAgreed}
                onChange={handleChangeIsTermsAgreed}
                inputProps={
                  {
                    'data-testid': 'sign-up-terms-checkbox',
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
                icon={
                  <img
                    src={checkboxNotcheckedIcon}
                    alt="checkboxNotcheckedIcon"
                  />
                }
                checkedIcon={
                  <img src={checkboxCheckedIcon} alt="checkboxCheckedIcon" />
                }
              />
            }
          />
        </StyledCautionBox>
      </StyledGridItem>
      <StyledGridItem>
        <BaseButton
          fullWidth
          type="button"
          disabled={
            !isValid ||
            !signUpUserInput.isTermsAgreed ||
            emailExistErrorMessage != ''
          }
          onClick={handleClickNextButton}
        >
          次へ
        </BaseButton>
      </StyledGridItem>
    </Grid>
  </StyledSection>
);

export { Presenter };
