import { createSelector } from 'reselect';
import { MutationError, MutationErrors } from '/@/api/graphql/client';
import { ErrorCodeEnumType } from '/@/api/graphql/internalApi/types';
import { RootState } from '/@/store';
import { STATUS, Status } from '/@/store/api/constants';
import { typeGuards } from '/@/utils/typeGuards';

export type ErrorMessage = string | null;

const selectVerifyEmailError = (state: RootState) =>
  state.api.verifyEmail.error;

export const selectVerifyEmailStatus = (state: RootState): Status =>
  state.api.verifyEmail.status;

export const selectIsVerifyEmailSuccess = createSelector(
  [selectVerifyEmailStatus],
  (veriftEmailStatus): boolean => veriftEmailStatus === STATUS.SUCCESS,
);

const selectMutationErrors = createSelector(
  [selectVerifyEmailError],
  (verifyEmailError): MutationErrors | null => {
    if (!verifyEmailError) {
      return null;
    }

    if (!typeGuards.api.isMutationErrors(verifyEmailError)) {
      return null;
    }
    return verifyEmailError;
  },
);

export const selectVerifyEmailErrorMessage = createSelector(
  [selectMutationErrors],
  (mutationErrors): ErrorMessage => {
    if (!mutationErrors) return null;

    const verifyEmailFailedError = mutationErrors.find((error) =>
      isVerifyEmailFailedError(error),
    );

    return verifyEmailFailedError ? verifyEmailFailedError.message : null;
  },
);

const isVerifyEmailFailedError = (error: MutationError) =>
  error.extensions.code === ErrorCodeEnumType.AlreadyConfirmed ||
  error.extensions.code === ErrorCodeEnumType.ExpiredToken ||
  error.extensions.code === ErrorCodeEnumType.NotConfirmationRequest;
