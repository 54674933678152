import { Container } from '@mui/material';
import { Grid } from '@mui/system';
import { styled } from '@mui/material/styles';
import React from 'react';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { ErrorMessage } from '/@/store/api/mutations/verifyEmail/selectors';
import { theme } from '/@/utils/theme';

type Props = {
  verifyEmailErrorMessage?: ErrorMessage;
};

const StyledMainContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  padding: theme.spacing(2),
}));
const StyledSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
}));
const StyledTitleContainer = styled('div')({
  color: theme.palette.secondary.dark,
});
const StyledTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'left',
}));

const Presenter: React.FC<Props> = ({ verifyEmailErrorMessage }) => (
  <MainLayout>
    <StyledMainContainer maxWidth="sm">
      <StyledSection maxWidth="sm" textAlign={'center'}>
        <StyledTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.LG}
            isBold
          >
            {verifyEmailErrorMessage}
          </BaseTypography>
        </StyledTitleContainer>
        <StyledTextContainer data-testid="completion-text-container">
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
            URLの有効期限は24時間です。
          </BaseTypography>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
            期限切れの場合はお手数ですが、再度新規登録をお願いいたします。
          </BaseTypography>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
            既に認証済みの場合はログインをお願いいたします。
          </BaseTypography>
        </StyledTextContainer>
      </StyledSection>
    </StyledMainContainer>
  </MainLayout>
);

export { Presenter };
