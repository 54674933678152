import React from 'react';
import { DetailSections } from '/@/components/shared/detailSections/Index';
import UsageImage from '/@/assets/shared/lp/howToUseSection/neo-signin-how-to-use-decoration.png';
import LineImage from '/@/assets/page/lpPage/signIn/lineImage.svg';

const Presenter: React.FC<Record<string, never>> = () => (
  <div data-testid="signInPageFeatureSection">
    <DetailSections
      title="FEATURE"
      subTitle="gift wallet でできること"
      details={[
        {
          number: 1,
          title: 'もらったギフトを一覧で管理できる',
          description:
            '「マイギフト」ぺージから確認できるので、ギフトの期限切れ、使い忘れを減らせます。',
          img: UsageImage,
        },
        {
          number: 2,
          title: '保存したチケットをすぐ表示できる',
          description:
            'gift wallet のLINEアカウントを開いて、チケットをタップするだけ。',
        },
        {
          number: 3,
          title: 'ギフトはLINEから確認できる',
          description:
            'ギフトがgift wallet に保存されると、LINEアカウントに通知されます。',
          img: LineImage,
        },
      ]}
    />
  </div>
);

export { Presenter };
