import { createSelector } from 'reselect';
import { Status, STATUS } from '../../api/constants';
import { RootState } from '/@/store';

export const selectSignUpStatus = (state: RootState): Status => {
  return state.api.createUserProfile.status;
};

export const selectIsSignUpLoading = createSelector(
  [selectSignUpStatus],
  (signUpStatus): boolean => {
    return signUpStatus === STATUS.LOADING;
  },
);

export const selectIsSignUpSuccess = createSelector(
  [selectSignUpStatus],
  (signUpStatus): boolean => {
    return signUpStatus === STATUS.SUCCESS;
  },
);

export const selectEmail = (state: RootState): string =>
  state.page.signUpPage.email;
