import { Reducer, combineReducers } from 'redux';

import { types } from './types';
import { GetExistSameConfirmedEmailGqlQuery } from '/@/api/graphql/publicApi/types';
import { RootAction } from '/@/store/actions';
import { getExistSameConfirmedEmailTypes } from '/@/store/api/queries/getExistSameConfirmedEmail';

type ExistSameConfirmedEmailState = ExistSameConfirmedEmail | null;
type ExistSameConfirmedEmail =
  GetExistSameConfirmedEmailGqlQuery['existSameConfirmedEmail'];

const existSameConfirmedEmail: Reducer<
  ExistSameConfirmedEmailState,
  RootAction
> = (state = null, action) => {
  switch (action.type) {
    case getExistSameConfirmedEmailTypes.GET_EXIST_SAME_CONFIRMED_EMAIL_SUCCESS:
      return action.existSameConfirmedEmail;
    default:
      return state;
  }
};

const email: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_EMAIL:
      return action.payload;
    default:
      return state;
  }
};

const password: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD:
      return action.payload;
    default:
      return state;
  }
};

const isTermsAgreed: Reducer<boolean, RootAction> = (state = false, action) => {
  switch (action.type) {
    case types.UPDATE_IS_TERMS_AGREED:
      return action.payload;
    default:
      return state;
  }
};

const prefectureCode: Reducer<number | null, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case types.UPDATE_PREFECTURE_CODE:
      return action.payload;
    default:
      return state;
  }
};

const genderCode: Reducer<number | null, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case types.UPDATE_GENDER_CODE:
      return action.payload;
    default:
      return state;
  }
};

const birthdayYear: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_BIRTHDAY_YEAR:
      return action.payload;
    default:
      return state;
  }
};

const birthdayMonth: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_BIRTHDAY_MONTH:
      return action.payload;
    default:
      return state;
  }
};

const birthdayDay: Reducer<string, RootAction> = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_BIRTHDAY_DAY:
      return action.payload;
    default:
      return state;
  }
};

const signUpPage = combineReducers({
  email,
  password,
  isTermsAgreed,
  prefectureCode,
  genderCode,
  birthdayYear,
  birthdayMonth,
  birthdayDay,
});

export { existSameConfirmedEmail, signUpPage };
export type { ExistSameConfirmedEmail, ExistSameConfirmedEmailState };
