import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoImage from '/@/assets/shared/logo/gift-wallet-logo-with-title.svg';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';

const StyledContainer = styled('div')({
  width: '100%',
});
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledLogoImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledLogoImage = styled('img')({
  display: 'block',
  width: '200px',
  margin: '0 auto',
});

type Props = Record<string, never>;

const Presenter: React.FC<Props> = () => (
  <StyledContainer data-testid="signInPageHeroSection">
    <Grid container direction="column" alignContent="center">
      <Grid size="grow" textAlign="center">
        <StyledTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.BODY}
            fontSize={FONT_SIZE.MD}
            isBold
          >
            もらったギフトがひと目でわかる
          </BaseTypography>
        </StyledTitleContainer>
        <StyledLogoImageContainer>
          <StyledLogoImage src={LogoImage} alt="gift Wallet Logo" />
        </StyledLogoImageContainer>
      </Grid>
    </Grid>
  </StyledContainer>
);

export { Presenter };
