import React, { useEffect, useRef } from 'react';
import { useAppSelector } from '/@/store';
import { Presenter } from './Presenter';
import { useAppDispatch } from '/@/store/hooks';
import { STATUS } from '/@/store/api/constants';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { getAvailablePointChargesThunk } from '/@/store/api/queries/getAvailablePointCharges';
import { groupedAvailablePointCharges } from '/@/store/page/pointLogsPage/availablePointChargesPage/selectors';

const Container: React.FC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();

  const groupedAvailablePointChargesByMonth = useAppSelector(
    groupedAvailablePointCharges,
  );
  const getAvailablePointChargesPageInfo = useAppSelector(
    (state) => state.page.pointLogsPage.availablePointChargesPage.pageInfo,
  );
  const getAvailablePointChargesStatus = useAppSelector(
    (state) => state.api.getAvailablePointCharges.status,
  );

  useEffect(() => {
    // pageInfoがnullの場合に初回ロードを行う
    if (getAvailablePointChargesPageInfo === null) {
      dispatch(getAvailablePointChargesThunk()).catch(() => {});
    }
  }, [dispatch, getAvailablePointChargesPageInfo]);

  // 無限スクロール対応
  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      getAvailablePointChargesStatus !== STATUS.LOADING &&
      getAvailablePointChargesPageInfo?.hasNextPage
    ) {
      dispatch(
        getAvailablePointChargesThunk({
          after: getAvailablePointChargesPageInfo.endCursor ?? undefined,
        }),
      ).catch(() => {});
    }
  }, [
    dispatch,
    isIntersect,
    getAvailablePointChargesStatus,
    groupedAvailablePointChargesByMonth,
    getAvailablePointChargesPageInfo,
  ]);

  return (
    <Presenter
      infiniteScrollTarget={infiniteScrollTarget}
      groupedAvailablePointChargesByMonth={groupedAvailablePointChargesByMonth}
      getAvailablePointChargesStatus={getAvailablePointChargesStatus}
    />
  );
};

export { Container as AvailablePointCharges };
