import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { saveGifteeContentThunk } from '/@/store/api/mutations/saveGifteeContent';
import { Presenter } from './Presenter';
import { useLocation, useNavigate } from 'react-router-dom';
import { OriginEnum } from '/@/api/graphql/internalApi/types';
import { PATHS } from '/@/routes/paths';
import { getCurrentUserThunk } from '/@/store/api/queries/getCurrentUser';
import { selectIsGetCurrentUserSuccess } from '/@/store/api/queries/getCurrentUser/selectors';
import { selectIsUserProfilePresent } from '/@/store/app/currentUser/selectors';
import {
  selectSaveGifteeContentStatus,
  selectIsSaveGifteeContentSuccess,
} from '/@/store/api/mutations/saveGifteeContent/selectors';
import { selectIsGetGifteeContentSuccess } from '/@/store/api/queries/getGifteeContent/selectors';
import { selectSelectedGifteeContent } from '/@/store/app/selectedGifteeContent/selectors';
import { getGifteeContentThunk } from '/@/store/api/queries/getGifteeContent';
import { selectDuplicatedGifteeContentErrorMessage } from '/@/store/api/mutations/saveGifteeContent/selectors';

const Container: React.FC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();

  const { search } = useLocation();

  const navigate = useNavigate();
  const saveGifteeContentStatus = useAppSelector(selectSaveGifteeContentStatus);
  const selectedGifteeContent = useAppSelector(selectSelectedGifteeContent);
  const isSaveGifteeContentSuccess = useAppSelector(
    selectIsSaveGifteeContentSuccess,
  );
  const isGetGifteeContentSuccess = useAppSelector(
    selectIsGetGifteeContentSuccess,
  );

  // Ensure the user owns profile before gift saving
  const isUserProfilePresent = useAppSelector(selectIsUserProfilePresent);
  const isGetCurrentUserSuccess = useAppSelector(selectIsGetCurrentUserSuccess);

  const duplicatedGifteeContentErrorMessage = useAppSelector(
    selectDuplicatedGifteeContentErrorMessage,
  );

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (!isUserProfilePresent) {
      dispatch(getCurrentUserThunk()).catch(() => {});
    }
  }, [dispatch, isUserProfilePresent]);

  useEffect(() => {
    const query = urlSearchParams.toString();

    if (isGetCurrentUserSuccess && !isUserProfilePresent) {
      navigate(PATHS.USER_PROFILE_NEW + '?' + query);
    }
  }, [
    search,
    isGetCurrentUserSuccess,
    isUserProfilePresent,
    navigate,
    urlSearchParams,
  ]);

  useEffect(() => {
    const url = urlSearchParams.get('gift_url');
    const origin = urlSearchParams.get('gift_origin') as OriginEnum | null;
    const referer = urlSearchParams.get('referer');

    if (isUserProfilePresent && url && origin) {
      dispatch(
        saveGifteeContentThunk({
          url,
          origin,
          referer,
          notifyTarget:
            urlSearchParams.get('convert_to_point') === 'true' ? false : true,
        }),
      ).catch(() => {});
    }
  }, [dispatch, search, isUserProfilePresent, urlSearchParams]);

  useEffect(() => {
    if (
      selectedGifteeContent &&
      (isSaveGifteeContentSuccess || isGetGifteeContentSuccess)
    ) {
      if (urlSearchParams.get('convert_to_point') === 'true') {
        navigate(
          `${PATHS.POINT_CHARGE}?my_gift_url_code=${selectedGifteeContent.myGifts![0].urlCode}`,
          { state: { skipConfirm: true } },
        );
      } else {
        navigate(`/gifts/${selectedGifteeContent.urlCode}`);
      }
    }
  }, [
    selectedGifteeContent,
    saveGifteeContentStatus,
    navigate,
    isSaveGifteeContentSuccess,
    isGetGifteeContentSuccess,
    urlSearchParams,
  ]);

  useEffect(() => {
    if (duplicatedGifteeContentErrorMessage) {
      dispatch(
        getGifteeContentThunk({ url: urlSearchParams.get('gift_url') }),
      ).catch(() => {});
    }
  }, [
    dispatch,
    search,
    isUserProfilePresent,
    duplicatedGifteeContentErrorMessage,
    navigate,
    urlSearchParams,
  ]);

  return <Presenter />;
};

export { Container as GiftNewPage };
