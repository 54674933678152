import React from 'react';
import { Box, Button, ButtonProps, styled } from '@mui/material';
import { ButtonLoader } from '/@/components/shared/loader/buttonLoader/Index';

type StyledButtonProps = ButtonProps & { isTransparent: boolean };
const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isTransparent',
})<StyledButtonProps>(({ theme, isTransparent }) => ({
  height: '48px',
  width: '100%',
  backgroundColor: isTransparent
    ? theme.palette.common.white
    : theme.palette.common.black,
  color: isTransparent
    ? theme.palette.secondary.dark
    : theme.palette.common.white,
  boxSizing: 'border-box',
  border: isTransparent ? `3px solid ${theme.palette.grey[100]}` : 'none',
  borderRadius: theme.spacing(4),
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: isTransparent
      ? theme.palette.common.black
      : theme.palette.common.white,
    color: isTransparent
      ? theme.palette.common.white
      : theme.palette.secondary.dark,
    border: isTransparent ? 'none' : `3px solid ${theme.palette.grey[100]}`,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.grey[700],
  },
}));

type Props = {
  loading?: boolean;
  isTransparent?: boolean;
  to?: string; // MEMO: Linkコンポーネントとして使う際に使用
} & ButtonProps;

const Presenter: React.FC<Props> = ({
  children,
  disabled,
  loading,
  isTransparent = false,
  ...buttonProps
}) => {
  return (
    <>
      <StyledButton
        {...buttonProps}
        disabled={loading || disabled}
        isTransparent={isTransparent}
      >
        {loading && (
          <Box mr={1}>
            <ButtonLoader />
          </Box>
        )}
        {children}
      </StyledButton>
    </>
  );
};

export type { Props as PresenterProps };

export { Presenter };
