import React, { useEffect } from 'react';
import { AlertProps, Collapse, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';
import {
  AlertMessageState,
  SEVERITY,
  Severity,
} from '/@/store/ui/alertMessage';
import successIcon from '/@/assets/shared/alertMessage/alert-message-success-icon.svg';
import errorIcon from '/@/assets/shared/alertMessage/alert-message-error-icon.svg';
import { Link } from 'react-router-dom';

type StyledAlertProps = AlertProps & { severity: Severity };
const StyledAlert = styled(Alert)<StyledAlertProps>(({ severity, theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  fontWeight: 'bold',
  borderRadius: theme.spacing(1),
  transition: 'opacity 0.5s linear',
  textAlign: 'left',
  ...(severity === SEVERITY.SUCCESS && {
    width: `calc(100% - ${theme.spacing(4)})`,
    position: 'fixed',
    top: theme.spacing(2),
    left: theme.spacing(2),
    margin: 0,
    color: theme.palette.grey[700],
    border: '1px solid ' + theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
    zIndex: 1500,
  }),
  ...(severity === SEVERITY.ERROR && {
    color: theme.palette.error.light,
    border: '1px solid #EB2300',
    backgroundColor: '#FDF3F2',
  }),
}));
const StyledMessage = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '0.875rem',
  lineHeight: '1.2',
});
const StyledLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  textDecoration: 'none',
  marginTop: theme.spacing(1),
}));
const StyledLinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  lineHeight: '1.2',
}));

type Props = {
  alertMessage: NonNullable<AlertMessageState>;
  handleInit: () => void;
};
const Presenter: React.FC<Props> = ({ alertMessage, handleInit }) => {
  useEffect(() => {
    // success時の表示時間
    if (alertMessage.severity === SEVERITY.SUCCESS) {
      const timer = setTimeout(() => {
        handleInit();
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alertMessage.severity, handleInit]);

  const icon = { src: '', alt: '' };

  switch (alertMessage.severity) {
    case SEVERITY.SUCCESS:
      icon.src = successIcon;
      icon.alt = 'successIcon';
      break;
    case SEVERITY.ERROR:
      icon.src = errorIcon;
      icon.alt = 'errorIcon';
      break;
    default:
      break;
  }

  return (
    <Collapse in={true}>
      <StyledAlert
        icon={<img src={icon.src} alt={icon.alt} width="20" height="20" />}
        severity={alertMessage.severity}
      >
        <StyledMessage variant="body2">{alertMessage.message}</StyledMessage>
        {alertMessage.link?.path && alertMessage.link?.text && (
          <StyledLink
            to={alertMessage.link.path}
            onClick={handleInit}
            data-gtm-click="shared-alertMessage-link"
          >
            <StyledLinkText variant="body2">
              {alertMessage.link.text}
            </StyledLinkText>
          </StyledLink>
        )}
      </StyledAlert>
    </Collapse>
  );
};

export { Presenter };
