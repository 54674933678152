import React from 'react';
import { Presenter } from './Presenter';
import { PointFeature } from '/@/api/graphql/internalApi/types';
import { useNavigate } from 'react-router-dom';
import type { Location } from 'history';

type Props = {
  pointFeatures: PointFeature[];
};

const Container: React.FC<Props> = ({ pointFeatures }) => {
  const navigate = useNavigate();
  const navigateDestination = (destination: Location) => {
    navigate(destination);
  };

  return (
    <Presenter
      pointFeatures={pointFeatures}
      navigateDestination={navigateDestination}
    />
  );
};

export { Container as FullImagePointFeatures };
