import React, { useState } from 'react';
import { Presenter } from './Presenter';

type Props = {
  fullWidth?: boolean;
};

const Container: React.FC<Props> = ({ fullWidth }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <Presenter
      isOpenModal={isOpenModal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      fullWidth={fullWidth}
    />
  );
};
export { Container as BenefitOfPointChargeModalWithButton };
