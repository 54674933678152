import { FetchResult } from '@apollo/client';
import { getExistSameConfirmedEmailGql } from './index.gql';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import {
  GetExistSameConfirmedEmailGqlQuery,
  GetExistSameConfirmedEmailGqlQueryVariables,
} from '/@/api/graphql/publicApi/types';

type GetExistSameConfirmedEmailResponse =
  FetchResult<GetExistSameConfirmedEmailGqlQuery>;

const getExistSameConfirmedEmail = (
  variables: GetExistSameConfirmedEmailGqlQueryVariables,
): Promise<GetExistSameConfirmedEmailResponse> =>
  publicApiClient.query({
    query: getExistSameConfirmedEmailGql,
    variables,
  });

export { getExistSameConfirmedEmail };

export type { GetExistSameConfirmedEmailResponse };
