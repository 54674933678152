import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { signInOrSignUpTypes } from '/@/store/api/mutations/signInOrSignUp';
import { verifyEmailTypes } from '/@/store/api/mutations/verifyEmail';

type Session = {
  token: string;
  expiredAt: string;
};

type CurrentSessionState = Session | null;

const currentSession: Reducer<CurrentSessionState, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case signInOrSignUpTypes.SIGN_IN_OR_SIGN_UP_WITH_LINE_SUCCESS:
      return action.session;
    case verifyEmailTypes.VERIFY_EMAIL_SUCCESS:
      return action.loginSession;
    default:
      return state;
  }
};

export { currentSession };
export type { CurrentSessionState, Session };
