import * as React from 'react';
import { styled } from '@mui/material/styles';

type StyledContainerProps = {
  isSticky: boolean;
};
const StyledContainer = styled('div')<StyledContainerProps>(
  ({ theme, isSticky }) => ({
    display: isSticky ? 'block' : 'none',
    height: '92px',
    width: '100%',
    padding: theme.spacing(2),
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.secondary.light}`,
  }),
);

type Props = {
  isSticky: boolean;
  children: React.ReactNode;
};

const Presenter: React.FC<Props> = ({ isSticky, children }) => {
  return <StyledContainer isSticky={isSticky}>{children}</StyledContainer>;
};

export { Presenter };
