import React from 'react';
import { Presenter, PresenterProps } from './Presenter';

const Container: React.FC<PresenterProps> = (props) => {
  return (
    <>
      <Presenter {...props}></Presenter>
    </>
  );
};

export { Container as LoadingButton };
