export const validateEmail: (value: string) => string | true = (email) => {
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
    return '正しいメールアドレスを入力してください';

  return true;
};

export const validatePassword: (value: string) => string | true = (
  password,
) => {
  if (password.length < 10) return 'パスワードは10文字以上で入力してください';

  const includeLowerCase = /[a-z]/.test(password);
  const includeUpperCase = /[A-Z]/.test(password);
  const includeNumber = /[0-9]/.test(password);
  if (!includeLowerCase || !includeUpperCase || !includeNumber)
    return 'パスワードは半角英小文字と英大文字、数字を組み合わせてください';

  return true;
};
