import {
  VerifyEmailFailure,
  VerifyEmailStart,
  VerifyEmailSuccess,
} from './actions';
import { handleVerifyEmailErrorThunk } from './handleError';
import type { ApiError } from '/@/api/graphql/client';
import type { VerifyEmailResponse } from '/@/api/graphql/publicApi/mutations/verifyEmail';
import { verifyEmail } from '/@/api/graphql/publicApi/mutations/verifyEmail';
import type { VerifyEmailInput } from '/@/api/graphql/publicApi/types';
import type { AppThunk } from '/@/store';

const verifyEmailThunk =
  (input: VerifyEmailInput): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(VerifyEmailStart());
    return verifyEmail(input)
      .then((response) => dispatch(verifyEmailSuccessThunk(response)))
      .catch((error) => dispatch(verifyEmailFailureThunk(error)));
  };

const verifyEmailSuccessThunk =
  (response: VerifyEmailResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { loginSession } = response.data!.verifyEmail;
    if (loginSession) dispatch(VerifyEmailSuccess(loginSession));
    return Promise.resolve();
  };

const verifyEmailFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(VerifyEmailFailure(error));
    return dispatch(handleVerifyEmailErrorThunk(error));
  };

export { verifyEmailThunk };
