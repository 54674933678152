import React from 'react';
import { ApolloClientErrors } from '/@/store/api/handleError';
import { NotFoundPage } from '/@/components/page/notFoundPage/Index';
import { InternalServerErrorPage } from '/@/components/page/internalServerErrorPage/Index';
import { PageErrorState } from '/@/store/app/pageError';

type OwnProps = {
  error: PageErrorState;
};

const Presenter: React.FC<OwnProps> = ({ error }) => {
  if (!error) {
    return null;
  }
  switch (error.status) {
    case ApolloClientErrors.AUTHENTICATION_ERROR.status:
      // MEMO: AppContainer側でログイン画面にリダイレクトしている
      return null;
    case ApolloClientErrors.NOT_FOUND_ERROR.status:
      return <NotFoundPage />;
    default:
      return <InternalServerErrorPage />;
  }
};

export { Presenter };
