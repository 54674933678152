import { handleErrorThunk } from '../../handleError';
import { selectVerifyEmailErrorMessage } from './selectors';
import { ApiError } from '/@/api/graphql/client';
import type { AppThunk } from '/@/store';

const handleVerifyEmailErrorThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch, getState) => {
    if (selectVerifyEmailErrorMessage(getState())) {
      return Promise.reject(error);
    }
    return dispatch(handleErrorThunk(error));
  };

export { handleVerifyEmailErrorThunk };
