import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Grid)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
});
const StyledCircularProgress = styled(CircularProgress)({
  color: '#1DABD6',
});

const Presenter: React.FC<Record<string, never>> = () => (
  <StyledContainer container justifyContent="center" alignItems="center">
    <StyledCircularProgress size="3rem" color="primary" />
  </StyledContainer>
);

export { Presenter };
