import React from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import { ButtonLoader } from '/@/components/shared/loader/buttonLoader/Index';

type Props = {
  loading: boolean;
} & ButtonProps;

const Presenter: React.FC<Props> = ({
  children,
  disabled,
  loading,
  ...buttonProps
}) => {
  return (
    <>
      <Button {...buttonProps} disabled={loading || disabled}>
        {loading && <ButtonLoader />}
        <Box ml={1}>{children}</Box>
      </Button>
    </>
  );
};

export type { Props as PresenterProps };

export { Presenter };
