import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import { GroupedUnavailableMyGifts } from '/@/store/app/unavailableMyGiftsConnection/selectors';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { BaseDivider } from '/@/components/shared/divider/Index';
const StyledUnavailableGiftsContainer = styled('div')({});
const StyledGroupedLabelContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));
const StyledMyGiftContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
const StyledUnavailableMyGiftsNotFoundContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(4, 2),
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(2),
}));
const StyledUnavailableMyGiftsNotFoundText = styled('div')({
  textAlign: 'center',
});

type Props = {
  groupedUnavailableMyGifts: GroupedUnavailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getUnavailableMyGiftsConnectionStatus: Status;
};

const Presenter: React.FC<Props> = ({
  groupedUnavailableMyGifts,
  infiniteScrollTarget,
  getUnavailableMyGiftsConnectionStatus,
}) => (
  <Grid data-testid="component-unavailableMyGifts">
    {Object.keys(groupedUnavailableMyGifts).length ? (
      <StyledUnavailableGiftsContainer>
        {Object.keys(groupedUnavailableMyGifts).map((date, index) => (
          <React.Fragment key={index}>
            <StyledGroupedLabelContainer>
              <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
                {date}
              </BaseTypography>
            </StyledGroupedLabelContainer>
            {groupedUnavailableMyGifts[date]?.map(
              (myGift) =>
                myGift && (
                  <React.Fragment key={myGift.urlCode}>
                    <BaseDivider />
                    <StyledMyGiftContainer key={myGift.urlCode}>
                      <MyGift myGift={myGift} />
                    </StyledMyGiftContainer>
                  </React.Fragment>
                ),
            )}
          </React.Fragment>
        ))}
      </StyledUnavailableGiftsContainer>
    ) : (
      getUnavailableMyGiftsConnectionStatus !== STATUS.IDLE &&
      getUnavailableMyGiftsConnectionStatus !== STATUS.LOADING && (
        <StyledUnavailableMyGiftsNotFoundContainer
          container
          justifyContent="center"
          direction="column"
        >
          <StyledUnavailableMyGiftsNotFoundText>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              現在利用済み・期限切れのギフトはありません。
            </BaseTypography>
          </StyledUnavailableMyGiftsNotFoundText>
        </StyledUnavailableMyGiftsNotFoundContainer>
      )
    )}
    {getUnavailableMyGiftsConnectionStatus === STATUS.LOADING && (
      <PartialLoader />
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
