import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { isPointsCharged } from '/@/api/graphql/internalApi/queries/getPointLogs';
import { useAppSelector } from '/@/store';

type OwnProps = {
  skipConfirm?: boolean;
};

const Container: React.FC<OwnProps> = ({ skipConfirm }) => {
  const [hasCharged, setHasCharged] = useState<boolean | null>(null);
  const giftConvertToPointRequestStatus = useAppSelector(
    (state) => state.api.getGiftConvertToPointRequest.status,
  );

  useEffect(() => {
    isPointsCharged(setHasCharged);
  }, []);

  return (
    <Presenter
      hasCharged={hasCharged}
      skipConfirm={skipConfirm}
      chargeStatus={giftConvertToPointRequestStatus}
    />
  );
};

export { Container as PointChargeSection };
