import { types } from './types';
import type { ApiError } from '/@/api/graphql/client';
import type { CreateUserRegistrationSessionMutation } from '/@/api/graphql/publicApi/types';

export const createUserRegistrationSessionStart = () =>
  ({ type: types.CREATE_USER_REGISTRATION_SESSION_START }) as const;

export const createUserRegistrationSessionSuccess = (
  success: CreateUserRegistrationSessionMutation['createUserRegistrationSession']['success'],
) =>
  ({
    type: types.CREATE_USER_REGISTRATION_SESSION_SUCCESS,
    success,
  }) as const;

export const createUserRegistrationSessionFailure = (error: ApiError) =>
  ({
    type: types.CREATE_USER_REGISTRATION_SESSION_FAILURE,
    error,
  }) as const;
