import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import '/@/assets/font/font.css';
import { POINT_FONT_SIZE, PointFontSize } from './Index';
import { theme } from '/@/utils/theme';

type StyledPointTextProps = {
  fontSize: PointFontSize;
};
const StyledPointText = styled(Typography)<StyledPointTextProps>(
  ({ fontSize }) => ({
    fontSize:
      fontSize === POINT_FONT_SIZE.LG
        ? '0.875rem'
        : fontSize === POINT_FONT_SIZE.XL
          ? '1rem'
          : '0.625rem',
    lineHeight: '1.6',
    color: theme.palette.secondary.dark,
  }),
);
type StyledPointsProps = {
  fontSize: PointFontSize;
};
const StyledPoints = styled('span')<StyledPointsProps>(({ fontSize }) => ({
  fontSize:
    fontSize === POINT_FONT_SIZE.LG
      ? '2rem'
      : fontSize === POINT_FONT_SIZE.XL
        ? '2.75rem'
        : '1.5rem',
  fontFamily: 'Grandstander-Bold',
  lineHeight: '1',
  fontWeight: 400,
  marginRight: '0.5rem',
}));

type Props = {
  points: number | null | undefined;
  fontSize: PointFontSize;
  isMinus: boolean;
};

const Presenter: React.FC<Props> = ({ points, fontSize, isMinus }) => (
  <StyledPointText fontSize={fontSize}>
    <StyledPoints fontSize={fontSize}>
      {typeof points === 'number'
        ? (isMinus ? '-' : '') + points.toLocaleString('ja-JP')
        : '-'}
    </StyledPoints>
    {'ポイント'}
  </StyledPointText>
);

export { Presenter };
