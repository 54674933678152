import React from 'react';
import { Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BenefitOfPointCharge } from '/@/components/shared/benefitOfPointCharge/illustration/Index';
import { ChargeableGift } from './chargeableGift/Index';
import { BenefitOfPointChargeModalWithButton } from '/@/components/shared/benefitOfPointCharge/modalWithButton/Index';
import { PageLoader } from '/@/components/shared/loader/pageLoader/Index';
import { PointChargeModal } from '/@/components/shared/modal/pointChargeModal/Index';
import { STATUS, Status } from '/@/store/api/constants';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  paddingBottom: 0,
}));
const StyledBackGround = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast + ' !important',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));
const StyledBenefitOfPointChargeSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(3),
}));
const StyledBenefitOfPointChargeModal = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type Props = {
  hasCharged: boolean | null;
  skipConfirm?: boolean;
  chargeStatus: Status;
};
const Presenter: React.FC<Props> = ({
  hasCharged,
  skipConfirm,
  chargeStatus,
}) => (
  <StyledContainer maxWidth="sm">
    <StyledBackGround>
      {skipConfirm === true ? (
        <>
          {chargeStatus === STATUS.LOADING && <PageLoader />}
          {/* skipConfirmがtrueの時、モーダル非表示で自動的にチャージ処理が実行される */}
          <PointChargeModal handleClose={() => {}} skipConfirm={skipConfirm} />
        </>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
          data-testid="component-pointChargeSection"
        >
          {hasCharged === false && (
            <StyledBenefitOfPointChargeSection>
              <BenefitOfPointCharge />
            </StyledBenefitOfPointChargeSection>
          )}
          <ChargeableGift />
          {hasCharged === true && (
            <StyledBenefitOfPointChargeModal
              container
              alignContent="center"
              justifyContent="center"
            >
              <BenefitOfPointChargeModalWithButton fullWidth />
            </StyledBenefitOfPointChargeModal>
          )}
        </Grid>
      )}
    </StyledBackGround>
  </StyledContainer>
);

export { Presenter };
