import { FetchResult } from '@apollo/client';
import { verifyEmailGql } from './index.gql';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import {
  VerifyEmailInput,
  VerifyEmailMutation,
} from '/@/api/graphql/publicApi/types';

type VerifyEmailResponse = FetchResult<VerifyEmailMutation>;

const verifyEmail = (
  verifyEmailInput: VerifyEmailInput,
): Promise<VerifyEmailResponse> => {
  return publicApiClient.mutate({
    mutation: verifyEmailGql,
    variables: { verifyEmailInput },
  });
};

export { verifyEmail };
export type { VerifyEmailResponse };
