import React from 'react';
import { Presenter } from './Presenter';
import { useAppSelector } from '/@/store';
import { selectEmail } from '/@/store/page/signUpPage/selectors';
const Container: React.FC<Record<string, never>> = () => {
  const email = useAppSelector(selectEmail);

  return <Presenter email={email} />;
};

export { Container as SignUpCompletionPage };
